import {
  themeColor,
  themeColorBlue,
  themeColorLight,
  white,
  dashBorderColor,
  mainTextColor,
  greyThemeLight,
  greyThemeDark1,
  greyThemeDark2,
  greyThemeDark4,
  greyThemeLighter,
  title,
  lightGrey1,
  greyishBorder,
  lightGrey12,
  lightBlue7,
  greyThemeDark6,
  accessibilityColors,
} from '@edulastic/colors'

// all theme variables

const defaultFontFamily = 'Open Sans'

const themeColorHover = '#029D49'
const playerButtonBackgroundColor = 'rgba(255, 255, 255, 0.15)'
const playerButtonBackgroundHoverColor = '#FFFFFF'
const buttonBackgroundColor = '#FFFFFF'
const buttonBackgroundHoverColor = '#F0F0F0'
const textColor = greyThemeDark1
const textColorActiveColor = '#FFFFFF'
const textColorGreenBg = '#FFFFFF'
const actionIconColor = themeColor
const actionIconActiveColor = '#FFFFFF'
const sideBarColor = '#FBFAFC'
const iconColor = '#7c93a7'
const borderColor = '#E1E1E1'
const linkFilledBgColor = '#FFFFFF'
const labelStyle = greyThemeDark1
const labelBgColor = '#F8F8FB'
const labelBgColorOverlayingContainer = '#FFFFFF'
const labelBgOverlayingContContainer = 'transparent'
const containerGrey = '#F8F8FB'
const containerWhite = '#FFFFFF'
const keyboardColor = '#91D8FF'
const keyboardHoverColor = '#7AC8F3'
const keyboardFontColor = title
const keyboardBorderColor = '#b3b3b3'
const keyboardBgColor = '#FFFFFF'
const numberpadBorderColor = '#FFFFFF'
const numberpadColor = '#FFFFFF'
const numType1BgColor = '#e5e5e5'
const numType2BgColor = '#d1d1d1'
const numType3BgColor = '#d0edfd'
const numType3Color = '#808080'
const numType4BgColor = '#91D8FF'
const numType4Color = '#738C9F'
const numType5BgColor = '#0EB08D'
const numType5Color = '#fff'
const numType6BgColor = '#50B29D'
const numType6Color = '#fff'
const numberpadBgColor = '#0EB08D'
const numberpadBgHoverColor = '#dfdfdf'
const numberpadBgDisable = '#FFFFFF'
const iconDeleteColor = themeColor
const iconDeleteHoverColor = '#FF0057'
const clearIconColor = '#FFFFFF'
const rightIconColor = '#36d29c'
const rightBgColor = '#E2FCF3'
const wrongIconColor = '#F25783'
const wrongBgColor = '#FDE0E9'
const rightBorderColor = '#5EB500'
const wrongBorderColor = '#DD2E44'
const noAnswerBgColor = '#F5F5F5'
const noAnswerIconColor = '#A7A7A7'
const noAnswerColor = '#b9b9b9'
const checkColor = '#36D29C'
const closeColor = '#F25783'
const questionTextColor = '#212E3A'
const questionTextBorderColor = '#d9d9d9'
const questionLabelColor = '#8ed863'
const triangleBottomColor = '#000000'
const uploadIcon = '#B2B2B2'
const zoneDropFontColor = '#B2B2B2'
const correctAnswerBoxBorderColor = '#E6E6E6'
const zoneDropFontColorAlt = '#B1B1B1'
const liColor = '#D8DBDE'
const checkboxBgColor = '#F8F8FB'
const shadowColor = '#E6E6EB'
const appBgColor = '#F3F3F8'
const progressStrokeColor = '#8FDBB2'
const disableButtonBgGreen = '#26B96A'
const descriptionColor = '#878282'
const breadcrumbColor = '#69727E'
const titleColor = '#304050'
const sidebarLabelColor = '#7C93A7'

const sidebarBgColorPlayer = '#F8F8F8'
const sidebarFontColorPlayer = title

const regular = '400'
const semiBold = '600'
const bold = '700'
const smallLinkFontSize = '10px'
const tagFontSize = '10px'
const linkFontSize = '11px'
const commentFontSize = '11px'
const subtitleFontSize = '20px'
const scoreTitleFontSize = '12px'
const extraSmallFontSize = '9px'
const smallFontSize = '12px'
const bodyFontSize = '13px'
const standardFont = '14px'
const keyboardFontSize = '16px'
const titleSectionFontSize = '16px'
const titleSecondarySectionFontSize = '18px'
const headerTitle = '22px'
const reviewPageHeaderFontSize = '18px'
const scoreFontSize = '26px'
const tableDataFont = '14px'
const headerFilterFontSize = '10px'
const classNameFontSize = '12px'
const formInputLabelSize = '12px'
const questionTextsmallFontSize = '13px'
const questionTextnormalFontSize = '14px'
const questionTextlargeFontSize = '15px'
const questionTextextraFontSize = '16px'
const questionTexthugeFontSize = '17px'
const questionTexticonFontSize = '13px'
const largeFontSize = '26px'
const headerLineHeight = '17px'

const notificationTitle = '18px'

const brandBlue = '#00b0ff'
const brandGreen = '#1FE3A1'
const brandDarkGreen = themeColor
const brandRed = '#EE1658'
const brandYellow = '#FDCC3B'
const brandDarkGrey = greyThemeDark1
const brandGrey = '#e5e5e5'
const brandLightGrey = '#F6F6F6'
const brandWhite = '#ffffff'
const warningColor = '#f4a700'
const dangerColor = '#ee1658'
const muteColor = '#cfcfcf'

const clockIcon = '#EE1658'

const markedAnswer = accessibilityColors.studentSubmitPage.markedAnswered
const markedSkipped = accessibilityColors.studentSubmitPage.markedSkipped
const markedForReview = accessibilityColors.studentSubmitPage.markedForReview

const tableBgColor = '#F8F8F8'
const tableColumnColor = '#AAAFB5'

const attemptTextColor = '#9CA0A9'

const progressGreen = '#C7EAD7'
const progressYellow = '#FDCC3B'
const progressRed = '#DD2E44'

const tagBgColor = '#CDF5E7'
const tagTextColor = '#4AAC8B'
const tagUnactiveBgColor = '#AAAFB5'
const tagUnactiveTextColor = '#AEAEAE'

const chartGridColor = '#e4e4e4'
const chartAxisLabelColor = greyThemeDark1
const chartTickColor = greyThemeDark1
const chartStockColor = greyThemeDark4
const pastDueTagBackground = '#F6C7D5'
const pastDueTagColor = '#CE5A7C'

// Text input field starts
const textFieldHeight = '36px'
// Text input field ends

const HeaderHeight = {
  xs: 62,
  sd: 42,
  md: 62,
  xl: 62,
}

const ThemeZoomMap = {
  xs: 1,
  sm: 1,
  md: 1,
  lg: 1,
  xl: 1,
}

const BannerHeight = 35

export const themeColorsMap = {
  MGLG: {
    themeColor: '#E5E5E5', // container color
    themeSecondaryColor: '#666', // text color
    themeMainBg: '#f4f4f4', // color behind the container
    title: 'Medium Gray on Light Gray',
  },
  BOR: {
    themeColor: '#ffd0ff',
    themeSecondaryColor: '#0e0b0f',
    themeMainBg: '#eebdee',
    title: 'Black on Rose',
  },
  RCON: {
    themeColor: '#000000',
    themeSecondaryColor: '#ffffff',
    themeMainBg: '#ffffff',
    title: 'Reverse Contrast',
  },
  YAB: {
    themeColor: '#003399',
    themeSecondaryColor: '#ffcc00',
    themeMainBg: '#3f84e5',
    title: 'Yellow on Blue',
  },
}

export const allThemeVars = {
  defaultFontFamily,
  themeColor,
  themeColorBlue,
  themeColorHover,
  buttonBackgroundColor,
  buttonBackgroundHoverColor,
  textColor,
  textColorActiveColor,
  actionIconColor,
  actionIconActiveColor,
  sideBarColor,
  iconColor,
  borderColor,
  linkFilledBgColor,
  labelStyle,
  labelBgColor,
  labelBgColorOverlayingContainer,
  containerGrey,
  containerWhite,
  keyboardColor,
  keyboardHoverColor,
  keyboardFontColor,
  keyboardBorderColor,
  numberpadBorderColor,
  numberpadColor,
  numType1BgColor,
  numType2BgColor,
  numType3BgColor,
  numType3Color,
  numType4BgColor,
  numType4Color,
  numType5BgColor,
  numType5Color,
  numType6BgColor,
  numType6Color,
  numberpadBgColor,
  numberpadBgHoverColor,
  numberpadBgDisable,
  iconDeleteColor,
  iconDeleteHoverColor,
  clearIconColor,
  rightIconColor,
  wrongIconColor,
  wrongBgColor,
  rightBorderColor,
  wrongBorderColor,
  checkColor,
  closeColor,
  questionTextColor,
  questionLabelColor,
  triangleBottomColor,
  uploadIcon,
  zoneDropFontColor,
  zoneDropFontColorAlt,
  liColor,
  checkboxBgColor,
  shadowColor,
  appBgColor,
  progressStrokeColor,
  disableButtonBgGreen,
  descriptionColor,
  breadcrumbColor,
  titleColor,
  classNameFontSize,
  sidebarBgColorPlayer,
  sidebarFontColorPlayer,
  questionLableColor: greyThemeDark4,

  regular,
  semiBold,
  bold,
  smallLinkFontSize,
  tagFontSize,
  linkFontSize,
  commentFontSize,
  subtitleFontSize,
  bodyFontSize,
  extraSmallFontSize,
  smallFontSize,
  standardFont,
  keyboardFontSize,
  titleSectionFontSize,
  headerTitle,
  reviewPageHeaderFontSize,
  scoreFontSize,
  headerFilterFontSize,
  largeFontSize,
  titleSecondarySectionFontSize,

  questionTextsmallFontSize,
  questionTextnormalFontSize,
  questionTextlargeFontSize,
  questionTextextraFontSize,
  questionTexthugeFontSize,
  questionTexticonFontSize,
  headerLineHeight,

  brandBlue,
  brandGreen,
  brandDarkGreen,
  brandRed,
  brandYellow,
  brandDarkGrey,
  brandGrey,
  brandLightGrey,
  brandWhite,
  warningColor,
  dangerColor,
  muteColor,

  clockIcon,

  markedAnswer,
  markedSkipped,
  markedForReview,

  tableBgColor,
  tableColumnColor,

  attemptTextColor,

  progressGreen,
  progressYellow,
  progressRed,

  tagBgColor,
  tagTextColor,
  tagUnactiveBgColor,
  tagUnactiveTextColor,
  HeaderHeight,
  BannerHeight,
}

export const defaultTheme = {
  ...allThemeVars,

  confirmationPopupButtonBgColor: themeColor,
  confirmationPopupButtonBgHoverColor: themeColorHover,
  confirmationPopupTextColor: textColor,
  confirmationPopupButtonTextColor: themeColor,
  confirmationPopupButtonTextHoverColor: linkFilledBgColor,
  confirmationPopupButtonTextSize: linkFontSize,
  confirmationPopupBodyTextSize: bodyFontSize,
  confirmationPopupTitleTextSize: notificationTitle,

  default: {
    // this default is required for Doc based asseeements
    headerBgColor: themeColor,
    sideToolbarBgColor: themeColor,
    dropdownHoverBorderColor: borderColor,
    dropdownCaretIconColor: themeColor,
    headerButtonBgColor: playerButtonBackgroundColor,
    headerButtonActiveBgColor: playerButtonBackgroundColor,
    headerButtonBgHoverColor: playerButtonBackgroundHoverColor,
    headerButtonIconColor: 'white',
    headerButtonIconActiveColor: themeColor,
    headerButtonBorderColor: 'white',
    headerButtonFontSize: '11px',
    headerButtonFontIconWidth: `12px`,
    headerButtonFontIconHeight: `9px`,
    headerToolbarButtonWidth: `36px`,
    headerToolbarButtonHeight: `36px`,
    headerRightButtonBgColor: buttonBackgroundColor,
    headerRightButtonBgHoverColor: buttonBackgroundHoverColor,
    headerRightButtonIconColor: actionIconColor,
    headerRightButtonFontIconWidth: `20px`, // save and exit button size
    headerRightButtonFontIconHeight: `20px`,
    headerScratchPadIconHeight: `23px`,
    headerScratchPadIconWidth: `20px`,
    headerProtactorIconWidth: `25px`,
    headerProtactorIconHeight: `25px`,
    headerCloseIconWidth: `16px`,
    headerCloseIconHeight: `16px`,
    headerCalculatorIconWidth: `19px`,
    headerCalculatorIconHeight: `19px`,
    headerMultiCalculatorIconWidth: `24px`,
    headerMultiCalculatorIconHeight: `24px`,
    headerInRulerIconWidth: `21px`,
    headerInRulerIconHeight: `21px`,
    headerCursorIconWidth: `10px`,
    headerCursorIconHeight: `15px`,
    headerLeftButtonHeight: `36px`,
    headerCheckIconWidth: `16px`,
    headerCheckIconHeight: `12px`,
    headerLightBulbIconWidth: `10px`,
    headerLightBulbIconHeight: `13px`,
    headerBookmarkIconWidth: `11px`,
    headerBookmarkIconHeight: `12px`,
    headerLeftButtonBgHoverColor: buttonBackgroundColor,
    pastDueTagBackground,
    pastDueTagColor,
    textFieldHeight,
  },
  header: {
    headerBgColor: white, // greenDark
    headerTitleTextColor: '#2F4151',
    headerTitleFontSize: headerTitle,
    headerTitleSecondaryTextSize: titleSecondarySectionFontSize,
    headerButtonColor: textColorGreenBg,
    headerButtonHoverColor: themeColor,
    navZoom: 1,
    headerClassTitleFontSize: '13px',
    settingsInputBorder: '#e1e1e1',
  },
  sideMenu: {
    sidebarBgColor: sideBarColor,
    sidebarTextColor: textColor,
    sidebarDividerColor: '#DFEDFA',
    helpButtonBgColor: buttonBackgroundColor,
    helpButtonBgHoverColor: themeColor,
    helpButtonFontSize: standardFont,
    helpButtonTextColor: sidebarLabelColor,
    helpButtonTextHoverColor: buttonBackgroundColor,
    helpIconColor: themeColor,
    helpIconHoverColor: actionIconActiveColor,
    dropdownIconColor: buttonBackgroundColor,
    userInfoButtonBgColor: accessibilityColors.sidebar.userInfo,
    userInfoButtonBgHoverColor: accessibilityColors.theme.blueHover,
    userInfoNameTextColor: textColorActiveColor,
    userInfoNameFontSize: standardFont,
    userInfoRoleTextColor: sidebarLabelColor,
    userInfoRoleFontSize: smallFontSize,
    userInfoDropdownBgColor: buttonBackgroundColor,
    userInfoDropdownItemBgColor: accessibilityColors.sidebar.userInfoDropdown,
    userInfoDropdownItemBgHoverColor: buttonBackgroundColor,
    userInfoDropdownItemTextColor: buttonBackgroundColor,
    userInfoDropdownItemTextHoverColor: themeColor,
    itemIconColor: iconColor,
    userInfoDropdownItemFontSize: standardFont,
    userInfoDropdownItemIconSize: standardFont,
    menuItemBgColor: sideBarColor,
    menuSelectedItemBgColor: themeColor,
    menuItemLinkColor: iconColor,
    menuItemTextSize: standardFont,
    menuSelectedItemLinkColor: actionIconActiveColor,
    menuItemLinkHoverColor: actionIconColor,
  },
  checkbox: {
    checkboxBorderColor: borderColor,
    checkboxCheckedColor: themeColor,
    checkboxIntermediateColor: themeColor,
    checkboxLabelColor: themeColor,
    rightBgColor,
    rightIconColor,
    wrongBgColor,
    wrongIconColor,
    noAnswerBgColor,
    noAnswerIconColor,
    textColor: '#2F4151',
  },
  common: {
    addNewChoiceButtonFontFamily: 'Open Sans',
    addNewChoiceButtonFontSize: linkFontSize,
    addNewChoiceButtonFontWeight: semiBold,
    addNewChoiceButtonFontStyle: linkFontSize,
    addNewChoiceButtonFontStretch: linkFontSize,
    addNewChoiceButtonBorderColor: 'none',
    addNewChoiceButtonColor: brandWhite,
    addNewChoiceButtonBgHoverColor: themeColorLight,
    addNewChoiceButtonHoverColor: brandWhite,
    addNewChoiceButtonBgActiveColor: themeColor,
    addNewChoiceButtonActiveColor: linkFilledBgColor,
    inputContainerTextTheme: questionTextColor,
    mathInputMathBorderColor: greyThemeLight,
    mathInputMathClearBgColor: clearIconColor,
    mathInputMathWrongBgColor: wrongBgColor,
    mathInputMathSuccessBgColor: rightBgColor,
    mathInputBgColor: greyThemeLighter,
    mathResponseEmbedFontSize: questionTextnormalFontSize,
    mathResponseEmbedCharBgColor: noAnswerIconColor,
    mathResponseEmbedCharColor: clearIconColor,
    mathResponseEmbedCharBorderColor: borderColor,
    mathResponseEmbedCharFontWeight: semiBold,
    mathResponseEmbedTextBgColor: clearIconColor,
    mathResponseFontColor: mainTextColor,
    mathResponseEmbedTextBorderColor: borderColor,
    mathResponseEmbedTextFontWeight: semiBold,
    mathResponseEmbedTextFontSize: smallLinkFontSize,
    pointBgColor: labelStyle,
    questionHeaderFontSize: questionTextextraFontSize,
    questionHeaderSmallFontSize: questionTextsmallFontSize,
    questionHeaderColor: textColor,
    subtitleFontSize,
    standardFont,
    bodyFontSize,
    titleSecondarySectionFontSize,
    commentFontSize,
    titleSectionFontSize,
    subtitleFontWeight: semiBold,
    subtitleFontStyle: 'normal',
    subtitleFontStretch: 'normal',
    subtitleColor: textColor,
    toolbarBgColor: brandLightGrey,
    toolbarBorderColor: 'none',
    triangleBorderLeftColor: 'transparent',
    triangleBorderRightColor: 'transparent',
    triangleBorderBottomColor: triangleBottomColor,
    partiallyCorrectScoreBlockBgColor: '#fbfae0',
    partiallyCorrectScoreBlockIconFillColor: '#dfd82c',
    incorrectScoreBlockIconFillColor: '#ed5b84',
    incorrectScoreBlockBgColor: '#fae1e9',
    correctScoreBlockBgColor: '#1fe3a11e',
    correctScoreBlockIconFillColor: '#1fe3a1',
  },
  manageDistrict: {
    refineResultsButtonTextColor: themeColor,
    refineResultsButtonTextFontSize: '11px',
    refineResultsButtonTextFontWeight: semiBold,
    refineResultsButtonBoxShadow: '0px 2px 4px #C9D0DB80',
    refineResultsButtonBorderRadius: '4px',
    createButtonTextColor: white,
    createButtonBgColor: themeColor,
    createButtonFontSize: '11px',
    createButtonFontWeight: semiBold,
    filterDivBgcolor: white,
    filterDivBorderRadius: '10px',
    searchDivBgColor: white,
    searchDivBorderRadius: '10px',
    searchDivBoxShadow: '0px 3px 10px #0000001A',
    tableContainerBgColor: white,
    tableContainerBorderRadius: '10px',
    tableContainerBoxShadow: '0px 3px 10px #0000001A',
    tableHeaderTxtColor: '#AAAFB5',
    tableHeaderTxtSize: '12px',
    tableRowColor: '#F8F8F8',
    tableRowTxtSize: '14px',
    tableRowTxtColor: greyThemeDark1,
    filterButtonFontSize: '11px',
    filterButtonFontWeight: semiBold,
    filterButtonBgColor: themeColor,
    filterButtonTextColor: white,
    inputFieldBgColor: '#F8F8F8',
    inputFieldBorderColor: '#E1E1E1',
    inputFieldBorderRadius: '2px',
    iconColor: themeColor,
    iconFontWeight: semiBold,
    modalBgcolor: '#F8F8F8',
    modalTitleColor: textColor,
    modalTitleFontSize: '22px',
    modalTitleFontWeight: bold,
    modalBodyBgcolor: white,
    modalBodyBoxShadow: '0px 2px 5px #00000012',
    modalBodyBorderRadius: '4px',
    formLabelFontSize: '11px',
    formLabelColor: textColor,
    modalFooterBtnTextFontSize: '11px',
    modalFooterBtnColor1: themeColor,
    modalFooterBtnColor2: 'transparent',
    modalFooterBtnTxtColor2: themeColor,
    modalFooterBtnTxtColor1: white,
  },
  dropZoneToolbar: {
    containerBgColor: containerGrey,
    labelFontWeight: semiBold,
    labelFontSize: subtitleFontSize,
    inputFontWeight: semiBold,
  },
  HOCwithPoints: {
    containerBgColor: containerGrey,
  },
  keyboard: {
    buttonBgColor: keyboardColor,
    buttonBgActiveClassColor: keyboardHoverColor,
    buttonBgHoverColor: keyboardHoverColor,
    buttonBgActiveColor: keyboardHoverColor,
    buttonColor: keyboardFontColor,
    buttonFontSize: keyboardFontSize,
    buttonFontWeight: semiBold,
    buttonBorderColor: keyboardBorderColor,
  },
  mathEssayInput: {
    inputLineWrapperBorderColor: greyThemeLight,
    inputLineButtonFontSize: linkFontSize,
    inputLineButtonBorderColor: borderColor,
    inputLineButtonBgHoverActiveClassColor: themeColorHover,
    inputLineButtonHoverActiveClassColor: white,
    inputLineLabelBgColor: labelBgColor,
    inputLineLabelColor: labelStyle,
    inputLineLabelFontSize: subtitleFontSize,
    inputWrapperBorderColor: greyThemeLight,
    inputWrapperActiveClassBorderColor: borderColor,
    inputWrapperActiveClassBorderLeftColor: borderColor,
    inputWrapperActiveClassBorderRightColor: borderColor,
  },
  mathKeyboard: {
    numFontFamily: 'Open Sans',
    dropDownFontFamily: 'Open Sans',
    keyboardBorderColor,
    keyboardBgColor,
    dropDownShadowColor: 'rgba(219, 219, 219, 0.55)',
    dropDownBgColor: containerWhite,
    dropDownColor: labelStyle,
    dropDownFontSize: standardFont,
    dropDownFontWeight: semiBold,
    dropDownFontStyle: 'normal',
    dropDownFontStretch: 'normal',
    dropDownIconColor: themeColor,
    closeButtonBorderColor: 'none',
    numBorderColor: numberpadBorderColor,
    numFontSize: keyboardFontSize,
    numFontWeight: semiBold,
    numFontStyle: 'normal',
    numFontStretch: 'normal',
    numColor: numberpadColor,
    numBgDisabledColor: numberpadBgDisable,
    numBorderDisabledColor: borderColor,
    numBgActiveColor: numberpadBgColor,
    numBgHoverColor: numberpadBgHoverColor,
    numHoverColor: numberpadColor,
    numType1BgColor,
    numType2BgColor,
    numType3BgColor,
    numType3Color,
    numType4BgColor,
    numType4Color,
    numType5BgColor,
    numType5Color,
    numType6BgColor,
    numType6Color,
  },
  numberPad: {
    itemBorderColor: numberpadBorderColor,
    itemColor: numberpadColor,
    itemBgColor: numberpadBgColor,
    itemBgHoverColor: numberpadBgHoverColor,
    itemBgActiveColor: numberpadBgHoverColor,
    itemFontWeight: semiBold,
  },
  sortableList: {
    iconTrashColor: greyThemeDark2,
    iconTrashHoverColor: iconDeleteHoverColor,
    iconTrashWrapperBgHoverColor: iconDeleteHoverColor,
    itemContainerBorderColor: borderColor,
    dragIconColor: themeColor,
    dragIconFontSize: questionTextextraFontSize,
    inputColor: labelBgColor,
    inputFontSize: subtitleFontSize,
  },
  typedList: {
    iconTrashColor: greyThemeDark2,
    iconTrashHoverColor: iconDeleteHoverColor,
    itemContainerBorderColor: borderColor,
    dragIconColor: themeColor,
    dragIconFontSize: questionTextextraFontSize,
  },
  styledDropZone: {
    loadingIconFontSize: questionTexticonFontSize,
    iconUploadColor: uploadIcon,
    iconUploadDragActiveColor: actionIconColor,
    containerColor: containerWhite,
    containerDragActiveColor: actionIconColor,
    zoneTitleFontSize: smallFontSize,
    zoneTitleCommentFontSize: extraSmallFontSize,
    zoneTitleFontWeight: bold,
    zoneTitleColor: zoneDropFontColor,
    zoneTitleAltColor: zoneDropFontColorAlt,
    underlinedColor: themeColorBlue,
    containerBorderColor: '#b9b9b9',
    containerBackground: containerGrey,
  },
  testItemPreview: {
    itemColBorderColor: borderColor,
    mobileLeftSideBgColor: themeColor,
    mobileRightSideBgColor: themeColor,
    iconArrowColor: actionIconActiveColor,
  },
  wordLimitAndCount: {
    labelFontSize: subtitleFontSize,
    labelFontWeight: semiBold,
    labelColor: labelStyle,
  },
  answerBox: {
    textColor: '#2F4151',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: greyishBorder,
    borderRadius: '4px',
    indexBoxColor: white,
    indexBoxBgColor: noAnswerIconColor,
  },
  correctAnswers: {
    iconPlusColor: actionIconActiveColor,
    iconCloseColor: greyThemeDark2,
    iconCloseHoverColor: 'none',
  },
  correctAnswerBoxLayout: {
    titleFontSize: questionTextextraFontSize,
  },
  dropContainer: {
    isOverBorderColor: greyThemeLight,
    isNotOverBorderColor: greyThemeLight,
    noBorderColor: 'none',
    dragItemContainerBgColor: '#f8f8f8',
  },
  dropArea: {
    iconTrashColor: iconDeleteColor,
    iconTrashHoverColor: iconDeleteHoverColor,
    draggableDashedBorderColor: borderColor,
    draggableSolidBorderColor: borderColor,
  },
  extras: {
    inputBorderColor: borderColor,
    inputBgColor: containerGrey,
  },
  questionMetadata: {
    antButtonCircleBgColor: themeColor,
    antButtonCircleShadowColor: 'none',
    antSelectSelectionBgColor: labelBgColorOverlayingContainer,
    antSelectSelectionChoiceBorderColor: borderColor,
    textColor,
    antSelectSelectionChoiceContentColor: textColor,
    antSelectSelectionChoiceContentFontSize: subtitleFontSize,
    antSelectSelectionChoiceContentFontWeight: semiBold,
    antSelectSelectionSelectedValueFontSize: standardFont,
    antSelectSelectionSelectedValueFontWeight: semiBold,
    antSelectSelectionSelectedValueColor: themeColorLight,
    antSelectSelectionSelectedValueBackground: labelBgColor,
    containerBackground: containerGrey,
    antSelectArrowIconColor: themeColor,
    curruculumNameFontWeight: semiBold,
    iconTrashColor: iconDeleteColor,
    iconHoverTrashColor: iconDeleteHoverColor,
    iconPencilEditColor: themeColor,
    selectSuffixIconColor: themeColor,
    selectSuffixIconFontSize: subtitleFontSize,
  },
  widgetOptions: {
    titleFontWeight: semiBold,
    blockBorderColor: borderColor,
    headingFontSize: standardFont,
    headingFontWeight: bold,
    headingFontStyle: 'normal',
    headingFontStretch: 'normal',
    headingColor: labelStyle,
    sectionHeadingFontSize: titleSectionFontSize,
    sectionHeadingFontWeight: bold,
    sectionHeadingFontStyle: 'normal',
    sectionHeadingFontStretch: 'normal',
    sectionHeadingColor: labelStyle,
    labelFontSize: standardFont,
    labelFontWeight: semiBold,
    labelFontStyle: 'normal',
    labelFontStretch: 'normal',
    labelColor: labelStyle,
    togglerBgColor: themeColor,
    togglerBgHoverColor: themeColorHover,
  },
  widgets: {
    classification: {
      subtitleFontSize,
      subtitleColor: labelStyle,
      previewSubtitleColor: labelStyle,
      previewItemFontWeight: semiBold,
      separatorBorderColor: borderColor,
      boxBgColor: containerWhite,
      boxBorderColor: borderColor,
      indexBoxFontSize: subtitleFontSize,
      indexBoxFontWeight: semiBold,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxValidBgColor: checkColor,
      indexBoxNotValidBgColor: closeColor,
      dragItemBgColor: containerWhite,
      dragItemValidBgColor: rightBgColor,
      dragItemNotValidBgColor: wrongBgColor,
      dragItemBorderColor: borderColor,
      dragItemValidBorderColor: rightIconColor,
      dragItemNotValidBorderColor: wrongBgColor,
      dragItemFontWeight: semiBold,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
      iconTrashColor: greyThemeDark2,
      iconTrashHoverColor: iconDeleteHoverColor,
      dropContainerBgColor: containerWhite,
    },
    clozeDragDrop: {
      editViewBgColor: containerWhite,
      previewTemplateBoxSmallFontSize: questionTextnormalFontSize,
      responseBoxBgColor: white,
      iconPlusColor: themeColor,
      groupResponseFieldsetBorderColor: borderColor,
      addGroupButtonBgColor: themeColor,
      responseContainerBgColor: brandLightGrey,
      correctAnswerBoxBorderColor,
      responseContainerSmallBorderColor: borderColor,
      responseContainerBorderColor: borderColor,
      groupDraggableBoxSmallFontSize: subtitleFontSize,
      draggableIconFontSize: subtitleFontSize,
      draggableBoxSmallFontSize: questionTextsmallFontSize,
      draggableBoxSmallFontWeight: semiBold,
      draggableBoxFontWeight: semiBold,
      deleteBgColor: containerGrey,
      rightIconColor,
      wrongIconColor,
    },
    clozeDropDown: {
      editViewBgColor: containerWhite,
      previewTemplateBoxSmallFontSize: subtitleFontSize,
      iconPlusColor: themeColor,
      deleteBgColor: themeColor,
      rightIconColor,
      wrongIconColor,
      questionContainerBorderColor: '#dfdfdf',
      boxBgColor: containerWhite,
      boxNoAnswerBgColor: noAnswerBgColor,
      boxWrongBgColor: wrongBgColor,
      boxBgCorrectColor: rightBgColor,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxNoAnswerBgColor: noAnswerColor,
      indexBoxIncorrectBgColor: closeColor,
      indexBoxCorrectBgColor: checkColor,
      indexBoxFontWeight: semiBold,
      indexBoxFontSize: subtitleFontSize,
      incorrectIndexColor: labelStyle,
      iconWrapperFontSize: headerTitle,
    },
    clozeImageDragDrop: {
      editViewBgColor: containerWhite,
      previewTemplateBoxSmallFontSize: subtitleFontSize,
      iconPlusColor: themeColor,
      dropContainerDashedBorderColor: borderColor,
      dropContainerSolidBorderColor: greyThemeLight,
      dragItemBorderColor: borderColor,
      answerBorderColor: borderColor,
      responseBoxBgColor: containerGrey,
      imageSettingsContainerBgColor: containerWhite,
      imageSettingsContainerFontSize: questionTextsmallFontSize,
      controlsBarBgColor: buttonBackgroundColor,
      iconDrawResizeColor: actionIconColor,
      iconUploadColor: uploadIcon,
      colorBoxBgColor: containerWhite,
      draggableBoxSmallFontSize: commentFontSize,
      dragItemIconFontSize: subtitleFontSize,
      correctAnswerBoxTitleColor: labelStyle,
      correctAnswerBoxSubtitleColor: labelStyle,
      correctAnswerBoxTextContainerFontWeight: semiBold,
      correctAnswerBoxTextContainerBgColor: containerWhite,
      correctAnswerBoxBorderColor,
      rightIconColor,
      wrongIconColor,
      imageZoom: 1,
      boxBgColor: containerWhite,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxFontWeight: semiBold,
      indexBoxFontSize: subtitleFontSize,
    },
    clozeImageDropDown: {
      pointsFontFamily: 'Open Sans',
      correctAnswerHeaderFontFamily: 'Open Sans',
      controlButtonFontFamily: 'Open Sans',
      responseContainerDashedBorderColor: greyThemeLight,
      responseContainerSolidBorderColor: greyThemeLight,
      iconPlusColor: themeColor,
      controlBarContainerBgColor: containerWhite,
      iconDrawResizeColor: actionIconColor,
      iconUploadColor: uploadIcon,
      antUploadHintColor: zoneDropFontColor,
      antUploadTextColor: zoneDropFontColorAlt,
      previewTemplateBoxSmallFontSize: commentFontSize,
      customQuilBorderColor: borderColor,
      pointsFontSize: subtitleFontSize,
      pointsFontWeight: semiBold,
      pointsColor: labelStyle,
      maxRespCountColor: labelStyle,
      maxRespCountFontWeight: semiBold,
      imageWidthColor: labelStyle,
      imageWidthFontWeight: semiBold,
      imageFlexViewBorderColor: borderColor,
      imageFlexViewBgColor: containerWhite,
      formContainerBgColor: containerGrey,
      formContainerColor: labelStyle,
      formContainerBorderColor: borderColor,
      formContainerFontSize: subtitleFontSize,
      formContainerFontWeight: semiBold,
      editorContainerBgColor: containerWhite,
      editorContainerShadowColor: 'none',
      correctAnswerHeaderBgColor: containerGrey,
      correctAnswerHeaderColor: labelStyle,
      correctAnswerHeaderFontSize: subtitleFontSize,
      correctAnswerHeaderFontWeight: semiBold,
      controlButtonBgColor: 'none',
      controlButtonColor: labelStyle,
      controlButtonNotDisabledBgColor: buttonBackgroundColor,
      controlButtonNotDisabledShadowColor: 'none',
      controlButtonFontSize: questionTextnormalFontSize,
      controlButtonFontWeight: semiBold,
      colorBoxBorderColor: borderColor,
      colorBoxBgColor: containerWhite,
      rightIconColor,
      wrongIconColor,
      antSelectSelectionBgColor: buttonBackgroundColor,
      antSelectSelectionBorderColor: borderColor,
      antSelectSelectionFontSize: '13px',
      antSelectSelectionFontWeight: semiBold,
      antSelectSelectionColor: labelStyle,
      antIconDownColor: actionIconColor,
      hrBorderColor: borderColor,
      imageZoom: 1,
      boxBgColor: containerWhite,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxFontWeight: semiBold,
      indexBoxFontSize: subtitleFontSize,
    },
    clozeImageText: {
      pointsFontFamily: 'Open Sans',
      correctAnswerHeaderFontFamily: 'Open Sans',
      controlButtonFontFamily: 'Open Sans',
      responseContainerDashedBorderColor: greyThemeLight,
      responseContainerSolidBorderColor: greyThemeLight,
      iconPlusColor: actionIconActiveColor,
      controlBarContainerBgColor: containerWhite,
      iconDrawResizeColor: actionIconColor,
      iconUploadColor: uploadIcon,
      antUploadHintColor: zoneDropFontColor,
      antUploadTextColor: zoneDropFontColorAlt,
      previewTemplateBoxSmallFontSize: commentFontSize,
      customQuilBorderColor: borderColor,
      pointsFontSize: subtitleFontSize,
      pointsFontWeight: semiBold,
      pointsColor: labelStyle,
      maxRespCountColor: labelStyle,
      maxRespCountFontWeight: semiBold,
      imageWidthColor: labelStyle,
      imageWidthFontWeight: semiBold,
      imageFlexViewBorderColor: borderColor,
      imageFlexViewBgColor: containerWhite,
      formContainerBgColor: containerGrey,
      formContainerColor: labelStyle,
      formContainerBorderColor: borderColor,
      formContainerFontSize: subtitleFontSize,
      formContainerFontWeight: semiBold,
      editorContainerBgColor: containerWhite,
      editorContainerShadowColor: 'none',
      correctAnswerHeaderBgColor: containerGrey,
      correctAnswerHeaderColor: labelStyle,
      correctAnswerHeaderFontSize: questionTextnormalFontSize,
      correctAnswerHeaderFontWeight: semiBold,
      controlButtonBgColor: 'none',
      controlButtonColor: labelStyle,
      controlButtonNotDisabledBgColor: buttonBackgroundColor,
      controlButtonNotDisabledShadowColor: 'none',
      controlButtonFontSize: standardFont,
      controlButtonFontWeight: semiBold,
      colorBoxBorderColor: borderColor,
      colorBoxBgColor: containerWhite,
      rightIconColor,
      wrongIconColor,
      imageZoom: 1,
      boxBgColor: containerWhite,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxFontWeight: semiBold,
      indexBoxFontSize: subtitleFontSize,
    },
    clozeText: {
      editViewBgColor: containerWhite,
      previewTemplateBoxSmallFontSize: questionTextnormalFontSize,
      iconPlusColor: actionIconColor,
      deleteBgColor: containerGrey,
      rightIconColor,
      wrongIconColor,
      textZoom: 1,
      questionContainerBorderColor: '#dfdfdf',
      boxBgColor: containerWhite,
      boxNoAnswerBgColor: noAnswerBgColor,
      boxWrongBgColor: wrongBgColor,
      boxBgCorrectColor: rightBgColor,
      indexBoxColor: containerWhite,
      indexBoxBgColor: themeColorLight,
      indexBoxNoAnswerBgColor: noAnswerIconColor,
      indexBoxIncorrectBgColor: closeColor,
      indexBoxCorrectBgColor: checkColor,
      indexBoxFontWeight: semiBold,
      indexBoxFontSize: subtitleFontSize,
      incorrectIndexColor: labelStyle,
      iconWrapperFontSize: headerTitle,
    },
    essayPlainText: {
      wordCountLimitedColor: closeColor,
      textInputBgColor: tableBgColor,
      textInputColor: labelStyle,
      textInputBorderColor: 'none',
      textInputLimitedBgColor: wrongBgColor,
      toolbarItemBgHoverColor: greyThemeLight,
      toolbarItemBgActiveColor: greyThemeDark2,
    },
    essayRichText: {
      qlBlocksFontFamily: 'Arial',
      wordCountLimitedColor: closeColor,
      textInputBgColor: tableBgColor,
      quillBgColor: 'none',
      quillTextColor: labelStyle,
      quillLimitedBgColor: wrongBgColor,
      qlBlocksBgColor: buttonBackgroundColor,
      qlBlocksBgActiveColor: greyThemeDark2,
      qlBlocksColor: iconColor,
      qlBlocksActiveColor: actionIconActiveColor,
      flexConBorderColor: borderColor,
      dragHandleFontSize: questionTextnormalFontSize,
      dragHandleColor: actionIconColor,
      sortableItemFontSize: questionTextextraFontSize,
      toolbarBgColor: 'none',
      toolbarColor: labelStyle,
      wordCountColor: 'none',
    },
    shortText: {
      subtitleFontSize,
      subtitleColor: labelStyle,
      correctInputBgColor: rightIconColor,
      incorrectInputBgColor: wrongBgColor,
      smallStimFontSize: questionTextnormalFontSize,
      smallStimFontWeight: regular,
      smallStimBoldFontWeight: semiBold,
      inputColor: questionTextColor,
      inputBorderColor: questionTextBorderColor,
      inputHoverBorderColor: themeColor,
    },
    formulaEssay: {
      instructorStimulusBgColor: containerGrey,
    },
    mathFormula: {
      inputColor: greyThemeLighter,
      inputCorrectColor: rightBgColor,
      inputCorrectBorderColor: rightBorderColor,
      inputIncorrectColor: wrongBgColor,
      inputIncorrectBorderColor: wrongBorderColor,
      iconTrashColor: greyThemeDark2,
      iconTrashHoverColor: iconDeleteHoverColor,
      quillBorderColor: borderColor,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
      iconWrapperBgColor: containerGrey,
      answerMethodContainerBorderColor: 'none',
      answerWrapperBgColor: containerGrey,
      answerBgColor: containerGrey,
    },
    highlightImage: {
      subtitleFontSize,
      subtitleColor: labelStyle,
      textFontSize: questionTextnormalFontSize,
      textFontWeight: regular,
      styledSelectBgColor: 'transparent',
      containerBgColor: containerWhite,
      containerBorderColor: borderColor,
      buttonBgColor: themeColor,
      buttonColor: linkFilledBgColor,
      buttonHoverColor: themeColorHover,
      buttonSvgColor: iconColor,
      buttonHoverSvgColor: iconColor,
      iconTrashColor: greyThemeDark2,
      iconTrashHoverColor: iconDeleteHoverColor,
    },
    shading: {
      liIconFontFamily: 'Open Sans',
      subtitleFontSize,
      subtitleColor: labelStyle,
      liIconFontSize: questionTexticonFontSize,
      liIconColor: clearIconColor,
      liBorderColor: '#2F4151',
      liBorderHoverColor: themeColorHover,
      correctLiBgColor: '#E2FCF3',
      correctLiBorderColor: '#35D19B',
      incorrectLiBgColor: '#FDE0E8',
      incorrectLiBorderColor: '#F25783',
      lockedLiBgColor: 'magenta',
      liBgColor: liColor,
      liBgHoverColor: 'transparent',
    },
    hotspot: {
      textFontFamily: 'Open Sans',
      subtitleFontSize,
      subtitleColor: labelStyle,
      svgMapFillColor: liColor,
      svgMapStrokeColor: actionIconColor,
      svgMapRightFillColor: '#00ad504d',
      svgMapRightStrokeColor: '#00AD50',
      intersectStrokeColor: '#F25783',
      intersectFillColor: '#f257834d',
      iconPlusColor: actionIconColor,
      iconCloseColor: themeColorLight,
      iconCloseHoverColor: closeColor,
      iconDrawColor: actionIconColor,
      iconTrashColor: actionIconColor,
      textFontWeight: semiBold,
      textFontSize: questionTextnormalFontSize,
      textFillColor: linkFilledBgColor,
      sideBarBgColor: buttonBackgroundColor,
      containerBgColor: containerWhite,
      containerBorderColor: borderColor,
      withShadowButtonActiveBgColor: greyThemeDark2,
      withShadowButtonBgColor: buttonBackgroundColor,
      withShadowButtonShadowColor: shadowColor,
      buttonBgColor: themeColor,
      buttonColor: linkFilledBgColor,
      buttonHoverColor: themeColorHover,
      buttonSvgColor: iconColor,
      buttonHoverSvgColor: themeColorHover,
      areaTextFontWeight: semiBold,
      areaTextFontSize: linkFontSize,
    },
    tokenHighlight: {
      previewSmallFontSize: commentFontSize,
      previewFontSize: questionTextnormalFontSize,
      correctResultBgColor: rightBgColor,
      incorrectResultBgColor: wrongBgColor,
      correctResultBorderColor: rightBorderColor,
      incorrectResultBorderColor: wrongBorderColor,
      modeButtonColor: buttonBackgroundColor,
      modeButtonBorderColor: 'none',
      modeButtonActiveBorderColor: 'none',
      modeButtonBgColor: themeColor,
      modeButtonActiveBgColor: themeColor,
      containerBgColor: containerGrey,
    },
    matchList: {
      containerBgColor: dashBorderColor,
      subtitleFontSize,
      subtitleColor: labelStyle,
      previewSubtitleColor: labelStyle,
      dragItemBgColor: clearIconColor,
      dragItemCorrectBgColor: rightBgColor,
      dragItemIncorrectBgColor: wrongBgColor,
      dragItemBorderColor: borderColor,
      dragItemColor: labelStyle,
      dragItemFontWeight: semiBold,
      groupSeparatorBorderColor: borderColor,
      separatorBorderColor: greyThemeDark2,
      correctAnswerBlockSeparatorBorderColor: keyboardBorderColor,
      separatorBgColor: greyThemeDark2,
      listItemFontWeight: semiBold,
      listItemColor: labelStyle,
      listItemBorderColor: lightGrey12,
      indexCorrectBgColor: checkColor,
      indexIncorrectBgColor: closeColor,
      indexColor: clearIconColor,
      indexFontWeight: semiBold,
      indexFontSize: questionTextnormalFontSize,
      corTitleFontWeight: semiBold,
      iconTrashColor: iconDeleteColor,
      iconTrashHoverColor: iconDeleteHoverColor,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
    },
    matrixChoice: {
      questionTextColor,
      quillBorderColor: borderColor,
      inlineLabelColor: themeColor,
      correctCellInputWrapperBgColor: rightBgColor,
      incorrectCellInputWrapperBgColor: wrongBgColor,
      tableStyledHeaderColor: themeColor,
      styledTableBorderColor: borderColor,
      styledTableThBgColor: clearIconColor,
    },
    multipleChoice: {
      iconPlusColor: actionIconColor,
      multiChoiceContentFontSize: questionTextnormalFontSize,
      multiChoiceContentFontWeight: semiBold,
      labelColor: labelStyle,
      labelBorderColor: '#b9b9b9',
      labelBorderHoverColor: 'none',
      labelCheckedBorderColor: rightBorderColor,
      labelCheckedBgColor: rightIconColor,
      labelRightBorderColor: rightBorderColor,
      labelRightBgColor: rightBgColor,
      labelWrongBorderColor: wrongBorderColor,
      labelWrongBgColor: wrongBgColor,
      labelIconFontSize: questionTexthugeFontSize,
      labelIconCheckColor: white,
      labelIconBgCheckColor: themeColor,
      labelIconSelectedCheckColor: themeColor,
      labelIconTimesColor: closeColor,
      labelOptionFontSize: questionTexticonFontSize,
      checkboxContainerBorderColor: borderColor,
      checkboxContainerBgColor: checkboxBgColor,
      checkboxContainerSmallFontSize: subtitleFontSize,
      checkboxContainerFontSize: questionTextnormalFontSize,
      checkboxContainerFontWeight: semiBold,
      checkboxContainerColor: labelStyle,
      checkboxContainerCheckedColor: linkFilledBgColor,
      checkboxContainerCheckedBgColor: themeColor,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
    },
    orderList: {
      correctAnswerTextColor: labelStyle,
      textBorderColor: borderColor,
      textSmallFontSize: subtitleFontSize,
      textFontSize: questionTextnormalFontSize,
      questionTextFontSize: questionTextnormalFontSize,
      questionTextColor: labelStyle,
      indexFontSize: standardFont,
      indexFontWeight: semiBold,
      indexColor: themeColorLight,
      incorrectIndexColor: labelStyle,
      iconWrapperFontSize: headerTitle,
      correctAnswerItemBgColor: containerWhite,
      correctAnswerItemBorderColor: labelStyle,
      containerBgColor: containerWhite,
      correctContainerBgColor: rightBgColor,
      incorrectContainerBgColor: wrongBgColor,
      correctContainerBorderColor: rightBorderColor,
      incorrectContainerBorderColor: wrongBorderColor,
      correctIconWrapperColor: checkColor,
      incorrectIconWrapperColor: closeColor,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
      dragHandleBorderColor: borderColor,
      dragHandleIconContainerColor: actionIconColor,
      dragHandleIconContainerHoverColor: themeColorHover,
      dragHandleIconContainerSmallFontSize: subtitleFontSize,
      dragHandleIconContainerFontSize: questionTextnormalFontSize,
    },
    sortList: {
      titleFontWeight: semiBold,
      titleFontSize: subtitleFontSize,
      titleColor: labelStyle,
      iconArrowColor: actionIconColor,
      iconArrowHoverColor: themeColorHover,
      iconArrowSmallFontSize: commentFontSize,
      iconArrowFontSize: questionTexticonFontSize,
      correctAnswersItemBgColor: clearIconColor,
      correctAnswersItemFontWeight: semiBold,
      correctAnswersIndexBgColor: themeColor,
      correctAnswersIndexColor: clearIconColor,
      dragItemActiveBgColor: containerGrey,
      dragItemBgColor: containerWhite,
      dragItemWithIndexFontSize: standardFont,
      dragItemWithIndexFontWeight: semiBold,
      dragItemTextEmptyFontSize: questionTextextraFontSize,
      dragItemTextEmptySmallFontSize: questionTextsmallFontSize,
      dragItemCorrectTextBorderColor: rightBorderColor,
      dragItemIncorrectTextBorderColor: wrongBorderColor,
      dragItemIndexBoxValidBgColor: checkColor,
      dragItemIndexBoxNotValidBgColor: wrongIconColor,
      dragItemCorrectTextBgColor: rightBgColor,
      dragItemIncorrectTextBgColor: wrongBgColor,
      dragItemTextFontSize: questionTextnormalFontSize,
      dragItemTextSmallFontSize: subtitleFontSize,
      dragItemContainerBgColor: '#f8f8f8',
      dragItemContainerBorderColor: greyThemeLight,
      iconCheckColor: checkColor,
      iconCloseColor: closeColor,
      styledDragHandleBorderColor: greyThemeLight,
      flexCenterFontWeight: semiBold,
      dragHandleContainerColor: themeColor,
      dragHandleContainerHoverColor: themeColorHover,
      dragHandleContainerSmallFontSize: questionTextnormalFontSize,
      dragHandleContainerFontSize: headerTitle,
      hrBorderColor: borderColor,
    },
    passage: {
      quillBorderColor: borderColor,
      instructorStimulusBgColor: labelBgColorOverlayingContainer,
      headingFontSize: headerTitle,
      headingFontWeight: bold,
    },
    chart: {
      bgColor: brandWhite,
      axisLabelFontWeight: bold,
      axisLabelFontSize: smallFontSize,
      axisLabelColor: labelStyle,
      axisBorderColor: brandGrey,
      labelStrokeColor: textColor,
      labelBgHoverColor: white,
      gridColor: chartGridColor,
      axisLabel: chartAxisLabelColor,
      tickColor: chartTickColor,
      stockColor: chartStockColor,
      chartZoom: 1,
    },
    fractionEditor: {
      wrongColor: wrongIconColor,
      correctColor: rightIconColor,
    },
    graphPlacement: {
      dragDropTitleFontWeight: bold,
      dragDropTitleFontSize: subtitleFontSize,
      buttonLabelStroke: textColor,
      toolbarBgColor: lightGrey1,
      buttonIconColor: greyThemeDark2,
      buttonActiveIconColor: greyThemeDark1,
      buttonActiveLabelStroke: greyThemeDark2,
      buttonActiveBgColor: brandWhite,
      buttonHoverBgColor: brandWhite,
      rightBgColor,
      rightIconColor,
      wrongBgColor,
      wrongIconColor,
      descriptionColor,
      backgroundShapes: greyThemeDark6,
    },
    quadrants: {
      dragDropTitleFontWeight: bold,
      dragDropTitleFontSize: subtitleFontSize,
    },
    axisLabels: {
      responseBoxTitleFontWeight: bold,
      responseBoxTitleFontSize: subtitleFontSize,
      responseBoxBgColor: '#F6F6F6',
      indexBoxFontSize: '14px',
      indexBoxFontWeight: semiBold,
      indexBoxColor: containerWhite,
      indexBoxBgColor: '#A7A7A7',
    },
    assessmentPlayers: {
      fontMono: "'Courier', monospace",
      fontSans: "'Source Sans Pro', 'Arial', sans-serif",
      fontSerif: "'Bree Serif', 'Times', serif",
      baseFontFamily: "'Source Sans Pro', 'Arial', sans-serif",
      sidebarFontFamily: "'Source Sans Pro', 'Arial', sans-serif",
      mainContentFontFamily: "'Source Sans Pro', 'Arial', sans-serif",
      brandBlue,
      brandGreen,
      brandDarkgreen: brandDarkGreen,
      brandRed,
      brandYellow,
      brandDarkgrey: brandDarkGrey,
      brandGrey,
      brandLightgrey: brandLightGrey,
      brandWhite,
      primary: themeColor,
      secondary: themeColorHover,
      warning: warningColor,
      danger: dangerColor,
      // muted: mutedColor,
      baseFontColor: labelStyle,
      baseFontSize: questionTextnormalFontSize,
      fontNormal: regular,
      fontSemibold: semiBold,
      fontBold: bold,
      baseLineHeight: 1.5,
      headings: '6 5 4 3 2 1',
      headerBarTextColor: linkFilledBgColor,
      headerTextColor: linkFilledBgColor,
      headerBgColor: themeColor,
      mainBgColor: appBgColor,
      mainContentTextColor: questionTextColor,
      mainContentBgColor: containerWhite,
      mainContentFontSize: questionTextnormalFontSize,
      sidebarBgColor: sidebarBgColorPlayer,
      sidebarFontSize: standardFont,
      sidebarContentBorderColor: themeColor,
      sidebarContentBackgroundColor: white,
      sidebarArrowIconColor: themeColor,
      sidebarActiveTextColor: actionIconColor,
      sidebarTextColor: sidebarFontColorPlayer,
      sidebarActiveCircleColor: themeColor,
      sideToolbarBgColor: themeColor,
      controlBtnTextColor: linkFilledBgColor,
      controlBtnPrimaryColor: themeColor,
      controlBtnSecondaryColor: 'none',
      controlBtnMutedColor: iconDeleteColor,
      selectTextColor: labelStyle,
      selectBgColor: clearIconColor,
      selectArrowColor: themeColor,
      quitAssessmentTextColor: themeColor,
      quitAssessmentFontSize: standardFont,
      timerIconColor: clearIconColor,
      progressStrokeColor,
      progressTrailColor: clearIconColor,
      saveIconColor: actionIconColor,
      submitIconColor: actionIconColor,
      CheckAnswerButtonText: actionIconColor,
      CheckAnswerButtonBg: buttonBackgroundColor,
      NextPrevButtonTextColor: linkFilledBgColor,
      NextPrevButtonBg: actionIconColor,
      timeDurationTextColor: linkFilledBgColor,
      saveIconBgColor: actionIconColor,
      logoColor: clearIconColor,
      questionsToAttemptBg: buttonBackgroundColor,
      questionsToAttemptTextColor: themeColor,
    },
    assessmentPlayerSimple: {
      logoColor: clearIconColor,
      progressStrokeColor,
      progressTrailColor: clearIconColor,
      headerBg: themeColor,
      headerIconBgColor: clearIconColor,
      headerIconHoverColor: buttonBackgroundHoverColor,
      headerIconColor: themeColor,
      progressFill: actionIconColor,
      btnDisabled: disableButtonBgGreen,
      mainContentTextColor: questionTextColor,
      mainContentBgColor: containerWhite,
      sidebarBgColor: sidebarBgColorPlayer,
      sidebarFontSize: smallFontSize,
      sidebarActiveTextColor: actionIconColor,
      sidebarTextColor: sidebarFontColorPlayer,
      footerButtonFontSizeLarge: linkFontSize,
      footerButtonFontSizeSmall: smallLinkFontSize,
      headerTitleFontSizeLarge: questionTexticonFontSize,
      headerFilterFontSizeSmall: reviewPageHeaderFontSize,
    },
    coding: {
      buttonTestCaseFileUpload: themeColor,
    },
  },
  classboard: {
    headerContainerColor: themeColorLight,
    headerAnchorLink: themeColorLight,
    headerBarbgcolor: brandGrey,
    headerCheckboxColor: '#1890ffd9',
    headerButtonColor: themeColorLight,
    SwitchColorheaderListColor: '#037fc2',
    SortBarSelectionColor: brandDarkGrey,
    ScoreCardColor: '#565e6d',
    ScoreCardParaColor: '#57b495',
    ScoreParaColor: '#565e6d',
    ScoreProgressColor: '#565e6d',
    CardPageColor: '#1890ffd9',
    CardCircularColor: '#5cb497',
    CardSqurebgColor: '#1fe3a0',
    CardColor: brandGrey,
    CardSqare: '#ee1b82',
    CardSquareDivColor: '#fdcc3a',
    CardDisneyColor: '#e1703e',
    SwitchColor: '#1fe3a0',
  },
  assignment: {
    cardTitleFontFamily: 'Open Sans',
    cardContainerBgColor: containerWhite,
    cardTitleColor: themeColor,
    cardTitleFontSize: titleSectionFontSize,
    cardAssingmnetTitleFontSize: standardFont,
    cardTimeIconType: 'clock-circle',
    cardTimeIconColor: clockIcon,
    cardTimeTextFontSize: bodyFontSize,
    cardTimeTextColor: labelStyle,
    cardSubmitLabelFontSize: smallLinkFontSize,
    cardSubmitLabelBgColor: themeColor,
    cardSubmitLabelTextColor: linkFilledBgColor,
    cardInProgressLabelBorderColor: 'none',
    cardInProgressLabelBgColor:
      accessibilityColors.studentStatus.inProgress.text,
    cardInProgressLabelTextColor: linkFilledBgColor,
    cardNotStartedLabelBorderColor: 'none',
    cardNotStartedLabelBgColor: accessibilityColors.labels.green.bg,
    cardNotStartedLabelTextColor: accessibilityColors.labels.green.text,
    cardSubmitedLabelBorderColor: 'none',
    cardSubmitedLabelBgColor: accessibilityColors.studentStatus.submitted.text,
    cardSubmitedLabelTextColor: linkFilledBgColor,
    cardGradedLabelBgColor: accessibilityColors.studentStatus.graded.text,
    cardGradedLabelTextColor: linkFilledBgColor,
    cardGradedLabelBorderColor: 'none',
    cardGradeHeldLabelBgColor:
      accessibilityColors.studentStatus.gradesHeld.text,
    cardGradeHeldLabelTextColor: linkFilledBgColor,
    cardGradeHeldLabelBorderColor: 'none',
    cardNotGradeLabelBgColor: accessibilityColors.studentStatus.notStarted.text,
    cardNotGradedLabelTextColor: linkFilledBgColor,
    cardNotGradedLabelBorderColor: 'none',
    cardMissedLabelBorderColor: 'none',
    cardMissedLabelBgColor: accessibilityColors.studentStatus.missed.text,
    cardMissedLabelTextColor: linkFilledBgColor,
    cardAbsentLabelBorderColor: 'none',
    cardAbsentLabelBgColor: accessibilityColors.studentStatus.absent.text,
    cardAbsentLabelTextColor: linkFilledBgColor,
    cardDefaultBtnFontSize: linkFontSize,
    cardDefaultBtnBgColor: buttonBackgroundColor,
    cardDefaultBtnBgHoverColor: themeColor,
    cardDefaultBtnTextColor: themeColor,
    cardDefaultBtnTextHoverColor: linkFilledBgColor,
    cardAttemptLinkTextColor: themeColor,
    cardAttemptLinkFontSize: scoreTitleFontSize,
    cardResponseBoxLabelsColor: labelStyle,
    cardResponseBoxLabelsFontSize: scoreTitleFontSize,
    cardAnswerAndScoreTextSize: scoreFontSize,
    cardAnswerAndScoreTextColor: labelStyle,
    attemptsReviewRowBgColor: tableBgColor,
    attemptsReviewRowTextColor: attemptTextColor,
    attemptsReviewRowFontSize: standardFont,
    attemptsRowReviewLinkSize: linkFontSize,
    attemptsRowReviewLinkColor: themeColorLight,
    dueDateFontSize: smallFontSize,
  },
  headerFilters: {
    headerFilterTextSize: headerFilterFontSize,
    headerFilterTextColor: themeColor,
    headerFilterBgColor: labelBgOverlayingContContainer,
    headerFilterBgBorderColor: themeColor,
    headerFilterTextHoverColor: linkFilledBgColor,
    headerFilterBgHoverColor: themeColor,
    headerFilterBgBorderHoverColor: themeColor,
    headerSelectedFilterTextColor: linkFilledBgColor,
    headerSelectedFilterBgColor: {
      all: brandDarkGreen,
      notStarted: accessibilityColors.studentStatus.notStarted.text,
      inProgress: accessibilityColors.studentStatus.inProgress.text,
      submitted: accessibilityColors.studentStatus.submitted.text,
      graded: accessibilityColors.studentStatus.graded.text,
      missed: accessibilityColors.studentStatus.missed.text,
    },
    headerSelectedFilterBgBorderColor: 'none',
  },
  breadcrumbs: {
    breadcrumbTextColor: breadcrumbColor,
    breadcrumbTextSize: smallLinkFontSize,
    breadcrumbLinkColor: breadcrumbColor,
  },
  reportList: {
    reportListTitleColor: themeColor,
    reportListTitleTextSize: titleSectionFontSize,
    reportListBackButtonBgColor: buttonBackgroundColor,
    reportListBackButtonBgBorderColor: themeColor,
    reportListBackButtonTextColor: themeColor,
    reportListBackButtonBgHoverColor: themeColor,
    reportListBackButtonBgBorderHoverColor: 'none',
    reportListBackButtonTextHoverColor: linkFilledBgColor,
    reportListBackButtonTextSize: linkFontSize,
    reportListTotalScoreTextColor: labelStyle,
    reportListTotalScoreTextSize: scoreFontSize,
    reportListQuestionBorderColor: 'none',
    reportListQuestionTextColor: questionTextColor,
    reportListQuestionTextSize: questionTextnormalFontSize,
    scoreBoxBorderBottomColor: 'none',
    teacherFeedbarLabelColor: tableBgColor,
    teacherFeedbarLabelFontSize: subtitleFontSize,
    teacherFeedbarTextColor: questionTextColor,
    teacherFeedbarTextSize: subtitleFontSize,
    reportListAnswerLabelColor: questionTextColor,
    reportListAnswerLabelFontSize: standardFont,
  },
  skillReport: {
    skillReportTitleColor: labelStyle,
    skillReportTitleFontSize: headerTitle,
    RelationTitleColor: labelStyle,
    RelationTitleFontSize: titleSectionFontSize,
    greenColor: progressGreen,
    yellowColor: progressYellow,
    redColor: progressRed,
    expandIconColor: actionIconColor,
    collapseIconColor: actionIconColor,
    gradeColumnTagBgColor: tagBgColor,
    gradeColumnTagColor: tagTextColor,
    gradeColumnTagTextSize: tagFontSize,
    tableHeaderBgColor: 'none',
    tableHeaderTextColor: tableColumnColor,
    tableHeaderHoverBgColor: 'none',
    tableHeaderHoverTextColor: 'none',
    tableHeaderTextSize: smallFontSize,
    percentageTagTextSize: smallFontSize,
    tableDataBgColor: tableBgColor,
    tableDataBgBorderColor: 'none',
    tableDataTextColor: labelStyle,
    tableDataFontSize: standardFont,
    percentageTagTextColor: triangleBottomColor,
    semiBold,
  },
  manageClass: {
    manageClassBgColor: containerWhite,
    manageClassBgBorderColor: borderColor,
    manageClassTdFontSize: tableDataFont,
    NoDataArchiveTextColor: notificationTitle,
    NoDataArchiveTextSize: headerTitle,
    NoDataArchiveSubTextColor: questionTextColor,
    NoDataArchiveSubTextSize: standardFont,
  },

  noData: {
    NoDataBgColor: containerWhite,
    NoDataBgBorderColor: borderColor,
    NoDataArchiveTextColor: labelStyle,
    NoDataArchiveTextSize: notificationTitle,
    NoDataArchiveSubTextColor: questionTextColor,
    NoDataArchiveSubTextSize: standardFont,
  },
  classCard: {
    cardBg: containerWhite,
    cardHeaderBorderColor: '#f2f2f2',
    cardBorderColor: '#dedada',
    cardTitleColor: themeColor,
    cardTitleTextSize: standardFont,
    cardVisitClassBtnBgColor: buttonBackgroundColor,
    cardVisitClassBtnBgHoverColor: themeColor,
    cardVisitClassBtnTextColor: themeColor,
    cardVisitClassBtnTextHoverColor: linkFilledBgColor,
    cardVisitClassBtnBorderColor: themeColor,
    cardVisitClassBtnTextSize: linkFontSize,
    cardUserInfoLabelColor: tableColumnColor,
    cardUserInfoLabelTextSize: tagFontSize,
    cardUserInfoContentColor: labelStyle,
    cardUserInfoFontSize: smallFontSize,
    cardInstitutionInfoTextSize: tagFontSize,
    cardInstitutionInfoTextColor: greyThemeDark1,
    cardActiveStatusBgColor: accessibilityColors.labels.green.bg,
    cardActiveStatusTextColor: accessibilityColors.labels.green.text,
    cardActiveStatusTextSize: tagFontSize,
    cardArchiveStatusBgColor: tagUnactiveBgColor,
    cardArchiveStatusTextColor: tagUnactiveTextColor,
    cardArchiveStatusTextSize: tagFontSize,
    cardInfoContentColor: labelStyle,
  },
  profile: {
    userHeadingTextSize: titleSectionFontSize,
    userHeadingTextColor: labelStyle,
    userHeadingTextWeight: bold,
    userSubTitleTextSize: subtitleFontSize,
    userSubTitleTextColor: labelStyle,
    uploadIconBgColor: actionIconColor,
    formInputLabelSize,
    formInputLabelColor: textColor,
    profileDetailFontSize: standardFont,
    saveButtonTextSize: linkFontSize,
    saveButtonTextColor: linkFilledBgColor,
    saveButtonBgColor: themeColor,
    saveButtonBorderColor: themeColor,
    cancelButtonTextColor: themeColor,
    cancelButtonBgColor: buttonBackgroundColor,
    changePasswordTextSize: linkFontSize,
    userDetailsTextColor: greyThemeDark1,
  },
  confirmation: {
    titleTextSize: notificationTitle,
    titleColor: labelStyle,
    descriptionTextSize: bodyFontSize,
    descriptionTextColor: labelStyle,
    buttonTextSize: linkFontSize,
    buttonBorderColor: themeColor,
    submitButtonTextColor: linkFilledBgColor,
    submitButtonBgColor: themeColor,
    cancelButtonTextColor: themeColor,
    cancelButtonBgColor: buttonBackgroundColor,
    modalWidth: 1,
  },
  attemptReview: {
    logoutIconColor: buttonBackgroundColor,
    logoutIconHoverColor: buttonBackgroundHoverColor,
    headingTextSize: reviewPageHeaderFontSize,
    headingColor: labelStyle,
    titleDescriptionTextSize: bodyFontSize,
    titleDescriptionTextColor: labelStyle,
    markedAnswerBoxColor: markedAnswer, // BlueColor
    markedSkippedBoxColor: markedSkipped, // GrayColor
    markedForReviewBoxColor: markedForReview, // YellowColor
    descriptionTextSize: smallFontSize,
    descriptionTextColor: greyThemeDark1,
    questiontextSize: questionTextextraFontSize,
    questiontextColor: questionTextColor,
    shortDescriptionTextSize: bodyFontSize,
    shortDescriptionTextColor: labelStyle,
    submitButtonTextSize: linkFontSize,
    submitButtonBgColor: themeColor,
    submitButtonTextColor: linkFilledBgColor,
  },
}

export const themes = {
  MGLG: {
    ...defaultTheme,
    questionTextColor: themeColorsMap.MGLG.themeSecondaryColor,
    themeColor: themeColorsMap.MGLG.themeColor,
    headerClassTitleColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerTitleSecondaryTextColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerDropdownBgColor: themeColorsMap.MGLG.themeColor,
    headerDropdownTextColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerDropdownBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerDropdownItemBgColor: themeColorsMap.MGLG.themeColor,
    headerDropdownItemBgHoverColor: '#e16c17',
    headerDropdownItemTextHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
    headerDropdownItemBgSelectedColor: '#216e8b',
    headerDropdownItemTextSelectedColor:
      themeColorsMap.MGLG.themeSecondaryColor,
    sectionBackgroundColor: themeColorsMap.MGLG.themeColor,

    confirmationPopupButtonBgColor: themeColorsMap.MGLG.themeSecondaryColor,
    confirmationPopupButtonBgHoverColor: themeColorsMap.MGLG.themeColor,
    confirmationPopupButtonTextColor: themeColorsMap.MGLG.themeSecondaryColor,
    confirmationPopupButtonTextHoverColor: themeColorsMap.MGLG.themeColor,
    confirmationPopupTextColor: themeColorsMap.MGLG.themeSecondaryColor,

    default: {
      ...defaultTheme.default,
      headerButtonBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerButtonIconColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    confirmation: {
      ...defaultTheme.confirmation,
      descriptionTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      submitButtonBgColor: themeColorsMap.MGLG.themeSecondaryColor,
      submitButtonTextColor: themeColorsMap.MGLG.themeColor,
    },
    sideMenu: {
      ...defaultTheme.sideMenu,
      sidebarBgColor: themeColorsMap.MGLG.themeColor,
      menuItemBgColor: themeColorsMap.MGLG.themeColor,
      menuSelectedItemBgColor: themeColorsMap.MGLG.themeSecondaryColor,
      menuItemLinkColor: themeColorsMap.MGLG.themeSecondaryColor,
      menuItemLinkHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      menuSelectedItemLinkColor: themeColorsMap.MGLG.themeColor,
    },
    header: {
      ...defaultTheme.header,
      headerBgColor: themeColorsMap.MGLG.themeColor,
      headerTitleTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerButtonColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerButtonHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    dropContainer: {
      ...defaultTheme.dropContainer,
      isOverBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    classCard: {
      ...defaultTheme.classCard,
      cardBg: themeColorsMap.MGLG.themeColor,
      cardTitleColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardVisitClassBtnBgColor: themeColorsMap.MGLG.themeColor,
      cardVisitClassBtnBgHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardVisitClassBtnTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardVisitClassBtnTextHoverColor: themeColorsMap.MGLG.themeColor,
      cardVisitClassBtnBorderColor: themeColorsMap.MGLG.themeColor,
      cardUserInfoLabelColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardActiveStatusBgColor: themeColorsMap.MGLG.themeColor,
      cardActiveStatusTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardArchiveStatusBgColor: themeColorsMap.MGLG.themeColor,
      cardInfoContentColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    headerFilters: {
      ...defaultTheme.headerFilters,
      headerSelectedFilterBgColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerFilterTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerFilterBgBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerFilterBgHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerFilterBgBorderHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      headerFilterTextHoverColor: themeColorsMap.MGLG.themeColor,
      headerSelectedFilterTextColor: themeColorsMap.MGLG.themeColor,
    },
    assignment: {
      ...defaultTheme.assignment,
      cardContainerBgColor: themeColorsMap.MGLG.themeColor,
      helpTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      helpHeadingTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardTitleColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardDefaultBtnBgColor: themeColorsMap.MGLG.themeColor,
      cardDefaultBtnBgHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardDefaultBtnTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      cardDefaultBtnTextHoverColor: themeColorsMap.MGLG.themeColor,
      cardTimeTextColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    breadcrumbs: {
      ...defaultTheme.breadcrumbs,
      breadcrumbTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      breadcrumbLinkColor: themeColorLight,
    },
    skillReport: {
      ...defaultTheme.skillReport,
      tableDataBgColor: themeColorsMap.MGLG.themeColor,
      tableDataTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      tableHeaderTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      skillReportTitleColor: themeColorsMap.MGLG.themeSecondaryColor,
      RelationTitleColor: themeColorsMap.MGLG.themeSecondaryColor,
    },
    common: {
      ...defaultTheme.common,
      subtitleColor: themeColorsMap.MGLG.themeSecondaryColor,
      mathInputBgColor: themeColorsMap.MGLG.themeColor,
      mathInputMathBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      toolbarBgColor: themeColorsMap.MGLG.themeColor,
      toolbarBorderColor: themeColorsMap.MGLG.toolbarBorderColor,
    },
    mathKeyboard: {
      numType1BgColor: themeColorsMap.MGLG.themeColor,
      numType2BgColor: themeColorsMap.MGLG.themeColor,
      numType3BgColor: themeColorsMap.MGLG.themeColor,
      numType3Color: themeColorsMap.MGLG.themeSecondaryColor,
      numType4BgColor: themeColorsMap.MGLG.themeColor,
      numType4Color: themeColorsMap.MGLG.themeSecondaryColor,
      numType5BgColor: themeColorsMap.MGLG.themeColor,
      numType5Color: themeColorsMap.MGLG.themeSecondaryColor,
      numType6BgColor: themeColorsMap.MGLG.themeColor,
      numType6Color: themeColorsMap.MGLG.themeSecondaryColor,
      numBgHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
      numHoverColor: themeColorsMap.MGLG.themeColor,
    },
    widgets: {
      ...defaultTheme.widgets,
      assessmentPlayers: {
        ...defaultTheme.widgets.assessmentPlayers,
        mainBgColor: themeColorsMap.MGLG.themeMainBg,
        mainContentBgColor: themeColorsMap.MGLG.themeColor,
        mainContentTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      multipleChoice: {
        ...defaultTheme.widgets.multipleChoice,
        labelIconCheckColor: themeColorsMap.MGLG.themeColor,
        labelIconSelectedCheckColor: themeColorsMap.MGLG.themeSecondaryColor,
        labelColor: themeColorsMap.MGLG.themeColor,
        labelBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
        checkboxContainerCheckedBgColor:
          themeColorsMap.MGLG.themeSecondaryColor,
      },
      matrixChoice: {
        ...defaultTheme.widgets.matrixChoice,
        questionTextColor: themeColorsMap.MGLG.themeSecondaryColor,
        inlineLabelColor: themeColorsMap.MGLG.themeSecondaryColor,
        inlineLabelHoverColor: themeColorsMap.MGLG.themeColor,
      },
      shortText: {
        ...defaultTheme.widgets.shortText,
        inputColor: themeColorsMap.MGLG.themeSecondaryColor,
        inputBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
        inputHoverBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      sortList: {
        ...defaultTheme.widgets.sortList,
        dragItemContainerBorderColor: themeColorsMap.MGLG.themeColor,
        dragHandleContainerColor: themeColorsMap.MGLG.themeSecondaryColor,
        dragHandleContainerHoverColor: themeColorsMap.MGLG.themeSecondaryColor,
        iconArrowColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      formulaEssay: {
        ...defaultTheme.widgets.formulaEssay,
        instructorStimulusBgColor: themeColorsMap.MGLG.themeColor,
      },
      clozeDragDrop: {
        ...defaultTheme.widgets.clozeDragDrop,
        responseContainerBgColor: themeColorsMap.MGLG.themeColor,
        responseContainerBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      essayRichText: {
        ...defaultTheme.widgets.essayRichText,
        toolbarBgColor: themeColorsMap.MGLG.themeColor,
        toolbarColor: themeColorsMap.MGLG.themeSecondaryColor,
        quillTextColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      orderList: {
        ...defaultTheme.widgets.orderList,
        questionTextColor: themeColorsMap.MGLG.themeSecondaryColor,
        dragHandleIconContainerColor: themeColorsMap.MGLG.themeSecondaryColor,
        dragHandleIconContainerHoverColor:
          themeColorsMap.MGLG.themeSecondaryColor,
        textBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
        dragHandleBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      essayPlainText: {
        ...defaultTheme.widgets.essayPlainText,
        textInputBgColor: themeColorsMap.MGLG.themeColor,
        textInputColor: themeColorsMap.MGLG.themeSecondaryColor,
        textInputBorderColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      matchList: {
        ...defaultTheme.widgets.matchList,
        dragItemColor: themeColorsMap.MGLG.themeSecondaryColor,
        containerBgColor: themeColorsMap.MGLG.themeColor,
        separatorBgColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      chart: {
        ...defaultTheme.widgets.chart,
        bgColor: themeColorsMap.MGLG.themeColor,
        labelStrokeColor: themeColorsMap.MGLG.themeSecondaryColor,
        labelBgHoverColor: themeColorsMap.MGLG.themeColor,
      },
      hotspot: {
        ...defaultTheme.widgets.hotspot,
        svgMapStrokeColor: themeColorsMap.MGLG.themeSecondaryColor,
      },
      graphPlacement: {
        ...defaultTheme.widgets.graphPlacement,
        buttonLabelStroke: themeColorsMap.MGLG.themeSecondaryColor,
        buttonActiveLabelStroke: themeColorsMap.MGLG.themeColor,
        buttonActiveBgColor: themeColorsMap.MGLG.themeSecondaryColor,
        buttonHoverBgColor: themeColorsMap.MGLG.themeColor,
      },
    },
  },
  BOR: {
    ...defaultTheme,
    questionTextColor: themeColorsMap.BOR.themeSecondaryColor,
    themeColor: themeColorsMap.BOR.themeColor,
    headerClassTitleColor: themeColorsMap.BOR.themeSecondaryColor,
    headerBorderColor: themeColorsMap.BOR.themeSecondaryColor,
    headerTitleSecondaryTextColor: themeColorsMap.BOR.themeSecondaryColor,
    headerDropdownBgColor: themeColorsMap.BOR.themeColor,
    headerDropdownTextColor: themeColorsMap.BOR.themeSecondaryColor,
    headerDropdownBorderColor: themeColorsMap.BOR.themeSecondaryColor,
    headerDropdownItemBgColor: themeColorsMap.BOR.themeColor,
    headerDropdownItemBgHoverColor: '#e16c17',
    headerDropdownItemTextHoverColor: themeColorsMap.BOR.themeSecondaryColor,
    headerDropdownItemBgSelectedColor: '#216e8b',
    headerDropdownItemTextSelectedColor: themeColorsMap.BOR.themeSecondaryColor,
    sectionBackgroundColor: themeColorsMap.BOR.themeColor,

    confirmationPopupButtonBgColor: themeColorsMap.BOR.themeSecondaryColor,
    confirmationPopupButtonBgHoverColor: themeColorsMap.BOR.themeColor,
    confirmationPopupButtonTextColor: themeColorsMap.BOR.themeSecondaryColor,
    confirmationPopupButtonTextHoverColor: themeColorsMap.BOR.themeColor,
    confirmationPopupTextColor: themeColorsMap.BOR.themeSecondaryColor,

    default: {
      ...defaultTheme.default,
      headerButtonBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      headerButtonIconColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    confirmation: {
      ...defaultTheme.confirmation,
      descriptionTextColor: themeColorsMap.BOR.themeSecondaryColor,
      submitButtonBgColor: themeColorsMap.BOR.themeSecondaryColor,
      submitButtonTextColor: themeColorsMap.BOR.themeColor,
    },
    sideMenu: {
      ...defaultTheme.sideMenu,
      sidebarBgColor: themeColorsMap.BOR.themeColor,
      menuItemBgColor: themeColorsMap.BOR.themeColor,
      menuSelectedItemBgColor: themeColorsMap.BOR.themeSecondaryColor,
      menuItemLinkColor: themeColorsMap.BOR.themeSecondaryColor,
      menuItemLinkHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      menuSelectedItemLinkColor: themeColorsMap.BOR.themeColor,
    },
    header: {
      ...defaultTheme.header,
      headerBgColor: themeColorsMap.BOR.themeColor,
      headerTitleTextColor: themeColorsMap.BOR.themeSecondaryColor,
      headerButtonColor: themeColorsMap.BOR.themeSecondaryColor,
      headerButtonHoverColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    dropContainer: {
      ...defaultTheme.dropContainer,
      isOverBorderColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    classCard: {
      ...defaultTheme.classCard,
      cardBg: themeColorsMap.BOR.themeColor,
      cardTitleColor: themeColorsMap.BOR.themeSecondaryColor,
      cardVisitClassBtnBgColor: themeColorsMap.BOR.themeColor,
      cardVisitClassBtnBgHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      cardVisitClassBtnTextColor: themeColorsMap.BOR.themeSecondaryColor,
      cardVisitClassBtnTextHoverColor: themeColorsMap.BOR.themeColor,
      cardVisitClassBtnBorderColor: themeColorsMap.BOR.themeColor,
      cardUserInfoLabelColor: themeColorsMap.BOR.themeSecondaryColor,
      cardActiveStatusBgColor: themeColorsMap.BOR.themeColor,
      cardActiveStatusTextColor: themeColorsMap.BOR.themeSecondaryColor,
      cardArchiveStatusBgColor: themeColorsMap.BOR.themeColor,
      cardInfoContentColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    headerFilters: {
      ...defaultTheme.headerFilters,
      headerSelectedFilterBgColor: themeColorsMap.BOR.themeSecondaryColor,
      headerFilterTextColor: themeColorsMap.BOR.themeSecondaryColor,
      headerFilterBgBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      headerFilterBgHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      headerFilterBgBorderHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      headerFilterTextHoverColor: themeColorsMap.BOR.themeColor,
      headerSelectedFilterTextColor: themeColorsMap.BOR.themeColor,
    },
    assignment: {
      ...defaultTheme.assignment,
      cardContainerBgColor: themeColorsMap.BOR.themeColor,
      helpTextColor: themeColorsMap.BOR.themeSecondaryColor,
      helpHeadingTextColor: themeColorsMap.BOR.themeSecondaryColor,
      cardTitleColor: themeColorsMap.BOR.themeSecondaryColor,
      cardDefaultBtnBgColor: themeColorsMap.BOR.themeColor,
      cardDefaultBtnBgHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      cardDefaultBtnTextColor: themeColorsMap.BOR.themeSecondaryColor,
      cardDefaultBtnTextHoverColor: themeColorsMap.BOR.themeColor,
      cardTimeTextColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    skillReport: {
      ...defaultTheme.skillReport,
      tableDataBgColor: themeColorsMap.BOR.themeColor,
      tableDataTextColor: themeColorsMap.BOR.themeSecondaryColor,
      tableHeaderTextColor: themeColorsMap.BOR.themeSecondaryColor,
      skillReportTitleColor: themeColorsMap.BOR.themeSecondaryColor,
      RelationTitleColor: themeColorsMap.BOR.themeSecondaryColor,
    },
    breadcrumbs: {
      ...defaultTheme.breadcrumbs,
      breadcrumbTextColor: themeColorsMap.BOR.themeSecondaryColor,
      breadcrumbLinkColor: themeColorLight,
    },
    common: {
      ...defaultTheme.common,
      subtitleColor: themeColorsMap.BOR.themeSecondaryColor,
      mathInputBgColor: themeColorsMap.BOR.themeColor,
      mathInputMathBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      toolbarBgColor: themeColorsMap.BOR.themeColor,
      toolbarBorderColor: themeColorsMap.BOR.toolbarBorderColor,
    },
    mathKeyboard: {
      numType1BgColor: themeColorsMap.BOR.themeColor,
      numType2BgColor: themeColorsMap.BOR.themeColor,
      numType3BgColor: themeColorsMap.BOR.themeColor,
      numType3Color: themeColorsMap.BOR.themeSecondaryColor,
      numType4BgColor: themeColorsMap.BOR.themeColor,
      numType4Color: themeColorsMap.BOR.themeSecondaryColor,
      numType5BgColor: themeColorsMap.BOR.themeColor,
      numType5Color: themeColorsMap.BOR.themeSecondaryColor,
      numType6BgColor: themeColorsMap.BOR.themeColor,
      numType6Color: themeColorsMap.BOR.themeSecondaryColor,
      numBgHoverColor: themeColorsMap.BOR.themeSecondaryColor,
      numHoverColor: themeColorsMap.BOR.themeColor,
    },
    widgets: {
      ...defaultTheme.widgets,
      assessmentPlayers: {
        ...defaultTheme.widgets.assessmentPlayers,
        mainBgColor: themeColorsMap.BOR.themeMainBg,
        mainContentBgColor: themeColorsMap.BOR.themeColor,
        mainContentTextColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      multipleChoice: {
        ...defaultTheme.widgets.multipleChoice,
        labelIconCheckColor: themeColorsMap.BOR.themeColor,
        labelIconSelectedCheckColor: themeColorsMap.BOR.themeSecondaryColor,
        labelColor: themeColorsMap.BOR.themeColor,
        labelBorderColor: themeColorsMap.BOR.themeSecondaryColor,
        checkboxContainerCheckedBgColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      matrixChoice: {
        ...defaultTheme.widgets.matrixChoice,
        questionTextColor: themeColorsMap.BOR.themeSecondaryColor,
        inlineLabelColor: themeColorsMap.BOR.themeSecondaryColor,
        inlineLabelHoverColor: themeColorsMap.BOR.themeColor,
      },
      shortText: {
        ...defaultTheme.widgets.shortText,
        inputColor: themeColorsMap.BOR.themeSecondaryColor,
        inputBorderColor: themeColorsMap.BOR.themeSecondaryColor,
        inputHoverBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      sortList: {
        ...defaultTheme.widgets.sortList,
        dragItemContainerBorderColor: themeColorsMap.BOR.themeColor,
        dragHandleContainerColor: themeColorsMap.BOR.themeSecondaryColor,
        dragHandleContainerHoverColor: themeColorsMap.BOR.themeSecondaryColor,
        iconArrowColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      formulaEssay: {
        ...defaultTheme.widgets.formulaEssay,
        instructorStimulusBgColor: themeColorsMap.BOR.themeColor,
      },
      clozeDragDrop: {
        ...defaultTheme.widgets.clozeDragDrop,
        responseContainerBgColor: themeColorsMap.BOR.themeColor,
        responseContainerBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      essayRichText: {
        ...defaultTheme.widgets.essayRichText,
        toolbarBgColor: themeColorsMap.BOR.themeColor,
        toolbarColor: themeColorsMap.BOR.themeSecondaryColor,
        quillTextColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      orderList: {
        ...defaultTheme.widgets.orderList,
        questionTextColor: themeColorsMap.BOR.themeSecondaryColor,
        dragHandleIconContainerColor: themeColorsMap.BOR.themeSecondaryColor,
        dragHandleIconContainerHoverColor:
          themeColorsMap.BOR.themeSecondaryColor,
        textBorderColor: themeColorsMap.BOR.themeSecondaryColor,
        dragHandleBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      essayPlainText: {
        ...defaultTheme.widgets.essayPlainText,
        textInputBgColor: themeColorsMap.BOR.themeColor,
        textInputColor: themeColorsMap.BOR.themeSecondaryColor,
        textInputBorderColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      matchList: {
        ...defaultTheme.widgets.matchList,
        dragItemColor: themeColorsMap.BOR.themeSecondaryColor,
        containerBgColor: themeColorsMap.BOR.themeColor,
        separatorBgColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      chart: {
        ...defaultTheme.widgets.chart,
        bgColor: themeColorsMap.BOR.themeColor,
        labelStrokeColor: themeColorsMap.BOR.themeSecondaryColor,
        labelBgHoverColor: themeColorsMap.BOR.themeColor,
      },
      hotspot: {
        ...defaultTheme.widgets.hotspot,
        svgMapStrokeColor: themeColorsMap.BOR.themeSecondaryColor,
      },
      graphPlacement: {
        ...defaultTheme.widgets.graphPlacement,
        buttonLabelStroke: themeColorsMap.BOR.themeSecondaryColor,
        buttonActiveLabelStroke: themeColorsMap.BOR.themeColor,
        buttonActiveBgColor: themeColorsMap.BOR.themeSecondaryColor,
        buttonHoverBgColor: themeColorsMap.BOR.themeColor,
      },
    },
  },
  RCON: {
    ...defaultTheme,
    questionTextColor: themeColorsMap.RCON.themeSecondaryColor,
    themeColor: themeColorsMap.RCON.themeColor,
    headerClassTitleColor: themeColorsMap.RCON.themeSecondaryColor,
    headerBorderColor: themeColorsMap.RCON.themeSecondaryColor,
    headerTitleSecondaryTextColor: themeColorsMap.RCON.themeSecondaryColor,
    headerDropdownBgColor: themeColorsMap.RCON.themeColor,
    headerDropdownTextColor: themeColorsMap.RCON.themeSecondaryColor,
    headerDropdownBorderColor: themeColorsMap.RCON.themeSecondaryColor,
    headerDropdownItemBgColor: themeColorsMap.RCON.themeColor,
    headerDropdownItemBgHoverColor: '#e16c17',
    headerDropdownItemTextHoverColor: themeColorsMap.RCON.themeSecondaryColor,
    headerDropdownItemBgSelectedColor: '#216e8b',
    headerDropdownItemTextSelectedColor:
      themeColorsMap.RCON.themeSecondaryColor,
    sectionBackgroundColor: themeColorsMap.RCON.themeColor,

    confirmationPopupButtonBgColor: themeColorsMap.RCON.themeSecondaryColor,
    confirmationPopupButtonBgHoverColor: themeColorsMap.RCON.themeColor,
    confirmationPopupButtonTextColor: themeColorsMap.RCON.themeSecondaryColor,
    confirmationPopupButtonTextHoverColor: themeColorsMap.RCON.themeColor,
    confirmationPopupTextColor: themeColorsMap.RCON.themeSecondaryColor,

    default: {
      ...defaultTheme.default,
      headerButtonBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      headerButtonIconColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    confirmation: {
      ...defaultTheme.confirmation,
      descriptionTextColor: themeColorsMap.RCON.themeSecondaryColor,
      submitButtonBgColor: themeColorsMap.RCON.themeSecondaryColor,
      submitButtonTextColor: themeColorsMap.RCON.themeColor,
    },
    sideMenu: {
      ...defaultTheme.sideMenu,
      sidebarBgColor: themeColorsMap.RCON.themeColor,
      menuItemBgColor: themeColorsMap.RCON.themeColor,
      menuSelectedItemBgColor: themeColorsMap.RCON.themeSecondaryColor,
      menuItemLinkColor: themeColorsMap.RCON.themeSecondaryColor,
      menuItemLinkHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      menuSelectedItemLinkColor: themeColorsMap.RCON.themeColor,
    },
    header: {
      ...defaultTheme.header,
      headerBgColor: themeColorsMap.RCON.themeColor,
      headerTitleTextColor: themeColorsMap.RCON.themeSecondaryColor,
      headerButtonColor: themeColorsMap.RCON.themeSecondaryColor,
      headerButtonHoverColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    dropContainer: {
      ...defaultTheme.dropContainer,
      isOverBorderColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    classCard: {
      ...defaultTheme.classCard,
      cardBg: themeColorsMap.RCON.themeColor,
      cardTitleColor: themeColorsMap.RCON.themeSecondaryColor,
      cardVisitClassBtnBgColor: themeColorsMap.RCON.themeColor,
      cardVisitClassBtnBgHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      cardVisitClassBtnTextColor: themeColorsMap.RCON.themeSecondaryColor,
      cardVisitClassBtnTextHoverColor: themeColorsMap.RCON.themeColor,
      cardVisitClassBtnBorderColor: themeColorsMap.RCON.themeColor,
      cardUserInfoLabelColor: themeColorsMap.RCON.themeSecondaryColor,
      cardActiveStatusBgColor: themeColorsMap.RCON.themeColor,
      cardActiveStatusTextColor: themeColorsMap.RCON.themeSecondaryColor,
      cardArchiveStatusBgColor: themeColorsMap.RCON.themeColor,
      cardInfoContentColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    headerFilters: {
      ...defaultTheme.headerFilters,
      headerSelectedFilterBgColor: themeColorsMap.RCON.themeSecondaryColor,
      headerFilterTextColor: themeColorsMap.RCON.themeSecondaryColor,
      headerFilterBgBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      headerFilterBgHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      headerFilterBgBorderHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      headerFilterTextHoverColor: themeColorsMap.RCON.themeColor,
      headerSelectedFilterTextColor: themeColorsMap.RCON.themeColor,
    },
    assignment: {
      ...defaultTheme.assignment,
      cardContainerBgColor: themeColorsMap.RCON.themeColor,
      helpTextColor: themeColorsMap.RCON.themeSecondaryColor,
      helpHeadingTextColor: themeColorsMap.RCON.themeSecondaryColor,
      cardTitleColor: themeColorsMap.RCON.themeSecondaryColor,
      cardDefaultBtnBgColor: themeColorsMap.RCON.themeColor,
      cardDefaultBtnBgHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      cardDefaultBtnTextColor: themeColorsMap.RCON.themeSecondaryColor,
      cardDefaultBtnTextHoverColor: themeColorsMap.RCON.themeColor,
      cardTimeTextColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    breadcrumbs: {
      ...defaultTheme.breadcrumbs,
      breadcrumbTextColor: themeColorsMap.RCON.themeSecondaryColor,
      breadcrumbLinkColor: themeColorLight,
    },
    skillReport: {
      ...defaultTheme.skillReport,
      tableDataBgColor: themeColorsMap.RCON.themeColor,
      tableDataTextColor: themeColorsMap.RCON.themeSecondaryColor,
      tableHeaderTextColor: themeColorsMap.RCON.themeSecondaryColor,
      skillReportTitleColor: themeColorsMap.RCON.themeSecondaryColor,
      RelationTitleColor: themeColorsMap.RCON.themeSecondaryColor,
    },
    common: {
      ...defaultTheme.common,
      subtitleColor: themeColorsMap.RCON.themeSecondaryColor,
      mathInputBgColor: themeColorsMap.RCON.themeColor,
      mathInputMathBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      toolbarBgColor: themeColorsMap.RCON.themeColor,
      toolbarBorderColor: themeColorsMap.RCON.toolbarBorderColor,
    },
    mathKeyboard: {
      numType1BgColor: themeColorsMap.RCON.themeColor,
      numType2BgColor: themeColorsMap.RCON.themeColor,
      numType3BgColor: themeColorsMap.RCON.themeColor,
      numType3Color: themeColorsMap.RCON.themeSecondaryColor,
      numType4BgColor: themeColorsMap.RCON.themeColor,
      numType4Color: themeColorsMap.RCON.themeSecondaryColor,
      numType5BgColor: themeColorsMap.RCON.themeColor,
      numType5Color: themeColorsMap.RCON.themeSecondaryColor,
      numType6BgColor: themeColorsMap.RCON.themeColor,
      numType6Color: themeColorsMap.RCON.themeSecondaryColor,
      numBgHoverColor: themeColorsMap.RCON.themeSecondaryColor,
      numHoverColor: themeColorsMap.RCON.themeColor,
    },
    widgets: {
      ...defaultTheme.widgets,
      assessmentPlayers: {
        ...defaultTheme.widgets.assessmentPlayers,
        mainBgColor: themeColorsMap.RCON.themeMainBg,
        mainContentBgColor: themeColorsMap.RCON.themeColor,
        mainContentTextColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      multipleChoice: {
        ...defaultTheme.widgets.multipleChoice,
        labelIconCheckColor: themeColorsMap.RCON.themeColor,
        labelIconSelectedCheckColor: themeColorsMap.RCON.themeSecondaryColor,
        labelColor: themeColorsMap.RCON.themeColor,
        labelBorderColor: themeColorsMap.RCON.themeSecondaryColor,
        checkboxContainerCheckedBgColor:
          themeColorsMap.RCON.themeSecondaryColor,
      },
      matrixChoice: {
        ...defaultTheme.widgets.matrixChoice,
        questionTextColor: themeColorsMap.RCON.themeSecondaryColor,
        inlineLabelColor: themeColorsMap.RCON.themeSecondaryColor,
        inlineLabelHoverColor: themeColorsMap.RCON.themeColor,
      },
      shortText: {
        ...defaultTheme.widgets.shortText,
        inputColor: themeColorsMap.RCON.themeColor,
        inputBorderColor: themeColorsMap.RCON.themeSecondaryColor,
        inputHoverBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      sortList: {
        ...defaultTheme.widgets.sortList,
        dragItemContainerBorderColor: themeColorsMap.RCON.themeColor,
        dragHandleContainerColor: themeColorsMap.RCON.themeSecondaryColor,
        dragHandleContainerHoverColor: themeColorsMap.RCON.themeSecondaryColor,
        iconArrowColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      formulaEssay: {
        ...defaultTheme.widgets.formulaEssay,
        instructorStimulusBgColor: themeColorsMap.RCON.themeColor,
      },
      clozeDragDrop: {
        ...defaultTheme.widgets.clozeDragDrop,
        responseContainerBgColor: themeColorsMap.RCON.themeColor,
        responseContainerBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      essayRichText: {
        ...defaultTheme.widgets.essayRichText,
        toolbarBgColor: themeColorsMap.RCON.themeColor,
        toolbarColor: themeColorsMap.RCON.themeColor,
        quillTextColor: themeColorsMap.RCON.themeColor,
        wordCountColor: themeColorsMap.RCON.themeColor,
      },
      orderList: {
        ...defaultTheme.widgets.orderList,
        questionTextColor: themeColorsMap.RCON.themeSecondaryColor,
        dragHandleIconContainerColor: themeColorsMap.RCON.themeSecondaryColor,
        dragHandleIconContainerHoverColor:
          themeColorsMap.RCON.themeSecondaryColor,
        textBorderColor: themeColorsMap.RCON.themeSecondaryColor,
        dragHandleBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      essayPlainText: {
        ...defaultTheme.widgets.essayPlainText,
        wordCountColor: themeColorsMap.RCON.themeColor,
        textInputBgColor: themeColorsMap.RCON.themeColor,
        textInputColor: themeColorsMap.RCON.themeSecondaryColor,
        textInputBorderColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      matchList: {
        ...defaultTheme.widgets.matchList,
        dragItemColor: themeColorsMap.RCON.themeSecondaryColor,
        containerBgColor: themeColorsMap.RCON.themeColor,
        separatorBgColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      chart: {
        ...defaultTheme.widgets.chart,
        bgColor: themeColorsMap.RCON.themeColor,
        labelStrokeColor: themeColorsMap.RCON.themeSecondaryColor,
        labelBgHoverColor: themeColorsMap.RCON.themeColor,
      },
      hotspot: {
        ...defaultTheme.widgets.hotspot,
        svgMapStrokeColor: themeColorsMap.RCON.themeSecondaryColor,
      },
      graphPlacement: {
        ...defaultTheme.widgets.graphPlacement,
        buttonLabelStroke: themeColorsMap.RCON.themeSecondaryColor,
        buttonActiveLabelStroke: themeColorsMap.RCON.themeColor,
        buttonActiveBgColor: themeColorsMap.RCON.themeSecondaryColor,
        buttonHoverBgColor: themeColorsMap.RCON.themeColor,
      },
    },
  },
  YAB: {
    ...defaultTheme,
    questionTextColor: themeColorsMap.YAB.themeSecondaryColor,
    themeColor: themeColorsMap.YAB.themeColor,
    headerClassTitleColor: themeColorsMap.YAB.themeSecondaryColor,
    headerBorderColor: themeColorsMap.YAB.themeSecondaryColor,
    headerTitleSecondaryTextColor: themeColorsMap.YAB.themeSecondaryColor,
    headerDropdownBgColor: themeColorsMap.YAB.themeColor,
    headerDropdownTextColor: themeColorsMap.YAB.themeSecondaryColor,
    headerDropdownBorderColor: themeColorsMap.YAB.themeSecondaryColor,
    headerDropdownItemBgColor: themeColorsMap.YAB.themeColor,
    headerDropdownItemBgHoverColor: '#e16c17',
    headerDropdownItemTextHoverColor: '#ffffff',
    headerDropdownItemBgSelectedColor: '#216e8b',
    headerDropdownItemTextSelectedColor: '#ffffff',
    sectionBackgroundColor: themeColorsMap.YAB.themeColor,

    confirmationPopupButtonBgColor: themeColorsMap.YAB.themeSecondaryColor,
    confirmationPopupButtonBgHoverColor: themeColorsMap.YAB.themeColor,
    confirmationPopupButtonTextColor: themeColorsMap.YAB.themeSecondaryColor,
    confirmationPopupButtonTextHoverColor: themeColorsMap.YAB.themeColor,
    confirmationPopupTextColor: themeColorsMap.YAB.themeSecondaryColor,

    default: {
      ...defaultTheme.default,
      headerButtonBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      headerButtonIconColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    confirmation: {
      ...defaultTheme.confirmation,
      descriptionTextColor: themeColorsMap.YAB.themeSecondaryColor,
      submitButtonBgColor: themeColorsMap.YAB.themeSecondaryColor,
      submitButtonTextColor: themeColorsMap.YAB.themeColor,
    },
    sideMenu: {
      ...defaultTheme.sideMenu,
      sidebarBgColor: themeColorsMap.YAB.themeColor,
      menuItemBgColor: themeColorsMap.YAB.themeColor,
      menuSelectedItemBgColor: themeColorsMap.YAB.themeSecondaryColor,
      menuItemLinkColor: themeColorsMap.YAB.themeSecondaryColor,
      menuItemLinkHoverColor: themeColorsMap.YAB.themeSecondaryColor,
      menuSelectedItemLinkColor: themeColorsMap.YAB.themeColor,
    },
    header: {
      ...defaultTheme.header,
      headerBgColor: themeColorsMap.YAB.themeColor,
      headerTitleTextColor: themeColorsMap.YAB.themeSecondaryColor,
      headerButtonColor: themeColorsMap.YAB.themeSecondaryColor,
      headerButtonHoverColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    dropContainer: {
      ...defaultTheme.dropContainer,
      isOverBorderColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    classCard: {
      ...defaultTheme.classCard,
      cardBg: themeColorsMap.YAB.themeColor,
      cardTitleColor: themeColorsMap.YAB.themeSecondaryColor,
      cardVisitClassBtnBgColor: themeColorsMap.YAB.themeColor,
      cardVisitClassBtnBgHoverColor: themeColorsMap.YAB.themeSecondaryColor,
      cardVisitClassBtnTextColor: themeColorsMap.YAB.themeSecondaryColor,
      cardVisitClassBtnTextHoverColor: themeColorsMap.YAB.themeColor,
      cardVisitClassBtnBorderColor: themeColorsMap.YAB.themeColor,
      cardUserInfoLabelColor: themeColorsMap.YAB.themeSecondaryColor,
      cardActiveStatusBgColor: themeColorsMap.YAB.themeColor,
      cardActiveStatusTextColor: themeColorsMap.YAB.themeSecondaryColor,
      cardArchiveStatusBgColor: themeColorsMap.YAB.themeColor,
      cardInfoContentColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    headerFilters: {
      ...defaultTheme.headerFilters,
      headerSelectedFilterBgColor: themeColorsMap.YAB.themeSecondaryColor,
      headerFilterTextColor: themeColorsMap.YAB.themeSecondaryColor,
      headerFilterBgBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      headerFilterBgHoverColor: themeColorsMap.YAB.themeSecondaryColor,
      headerFilterBgBorderHoverColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    assignment: {
      ...defaultTheme.assignment,
      cardContainerBgColor: '#003399',
      helpTextColor: themeColorsMap.YAB.themeSecondaryColor,
      helpHeadingTextColor: themeColorsMap.YAB.themeSecondaryColor,
      cardTitleColor: themeColorsMap.YAB.themeSecondaryColor,
      cardDefaultBtnBgColor: themeColorsMap.YAB.themeColor,
      cardDefaultBtnBgHoverColor: themeColorsMap.YAB.themeSecondaryColor,
      cardDefaultBtnTextColor: themeColorsMap.YAB.themeSecondaryColor,
      cardDefaultBtnTextHoverColor: themeColorsMap.YAB.themeColor,
      cardTimeTextColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    breadcrumbs: {
      ...defaultTheme.breadcrumbs,
      breadcrumbTextColor: themeColorsMap.YAB.themeSecondaryColor,
      breadcrumbLinkColor: themeColorLight,
    },
    skillReport: {
      ...defaultTheme.skillReport,
      tableDataBgColor: themeColorsMap.YAB.themeColor,
      tableDataTextColor: themeColorsMap.YAB.themeSecondaryColor,
      tableHeaderTextColor: themeColorsMap.YAB.themeSecondaryColor,
      skillReportTitleColor: themeColorsMap.YAB.themeSecondaryColor,
      RelationTitleColor: themeColorsMap.YAB.themeSecondaryColor,
    },
    common: {
      ...defaultTheme.common,
      subtitleColor: themeColorsMap.YAB.themeSecondaryColor,
      mathInputBgColor: themeColorsMap.YAB.themeColor,
      mathInputMathBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      toolbarBgColor: themeColorsMap.YAB.themeColor,
      toolbarBorderColor: themeColorsMap.YAB.toolbarBorderColor,
    },
    mathKeyboard: {
      numType1BgColor: themeColorsMap.YAB.themeColor,
      numType2BgColor: themeColorsMap.YAB.themeColor,
      numType3BgColor: themeColorsMap.YAB.themeColor,
      numType3Color: themeColorsMap.YAB.themeSecondaryColor,
      numType4BgColor: themeColorsMap.YAB.themeColor,
      numType4Color: themeColorsMap.YAB.themeSecondaryColor,
      numType5BgColor: themeColorsMap.YAB.themeColor,
      numType5Color: themeColorsMap.YAB.themeSecondaryColor,
      numType6BgColor: themeColorsMap.YAB.themeColor,
      numType6Color: themeColorsMap.YAB.themeSecondaryColor,
      numBgHoverColor: themeColorsMap.YAB.themeSecondaryColor,
      numHoverColor: themeColorsMap.YAB.themeColor,
    },
    widgets: {
      ...defaultTheme.widgets,
      assessmentPlayers: {
        ...defaultTheme.widgets.assessmentPlayers,
        mainBgColor: themeColorsMap.YAB.themeMainBg,
        mainContentBgColor: themeColorsMap.YAB.themeColor,
        mainContentTextColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      multipleChoice: {
        ...defaultTheme.widgets.multipleChoice,
        labelIconCheckColor: themeColorsMap.YAB.themeColor,
        labelIconSelectedCheckColor: themeColorsMap.YAB.themeSecondaryColor,
        labelColor: themeColorsMap.YAB.themeColor,
        labelBorderColor: themeColorsMap.YAB.themeSecondaryColor,
        checkboxContainerCheckedBgColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      matrixChoice: {
        ...defaultTheme.widgets.matrixChoice,
        questionTextColor: themeColorsMap.YAB.themeSecondaryColor,
        inlineLabelColor: themeColorsMap.YAB.themeSecondaryColor,
        inlineLabelHoverColor: themeColorsMap.YAB.themeColor,
        tableStyledHeaderColor: themeColorsMap.YAB.themeColor,
      },
      shortText: {
        ...defaultTheme.widgets.shortText,
        inputColor: themeColorsMap.YAB.themeSecondaryColor,
        inputBorderColor: themeColorsMap.YAB.themeSecondaryColor,
        inputHoverBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      sortList: {
        ...defaultTheme.widgets.sortList,
        dragItemContainerBorderColor: themeColorsMap.YAB.themeColor,
        dragHandleContainerColor: themeColorsMap.YAB.themeSecondaryColor,
        dragHandleContainerHoverColor: themeColorsMap.YAB.themeSecondaryColor,
        iconArrowColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      formulaEssay: {
        ...defaultTheme.widgets.formulaEssay,
        instructorStimulusBgColor: themeColorsMap.YAB.themeColor,
      },
      clozeDragDrop: {
        ...defaultTheme.widgets.clozeDragDrop,
        responseContainerBgColor: themeColorsMap.YAB.themeColor,
        responseContainerBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      essayRichText: {
        ...defaultTheme.widgets.essayRichText,
        toolbarBgColor: themeColorsMap.YAB.themeColor,
        toolbarColor: themeColorsMap.YAB.themeSecondaryColor,
        quillTextColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      orderList: {
        ...defaultTheme.widgets.orderList,
        questionTextColor: themeColorsMap.YAB.themeSecondaryColor,
        dragHandleIconContainerColor: themeColorsMap.YAB.themeSecondaryColor,
        dragHandleIconContainerHoverColor:
          themeColorsMap.YAB.themeSecondaryColor,
        textBorderColor: themeColorsMap.YAB.themeSecondaryColor,
        dragHandleBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      essayPlainText: {
        ...defaultTheme.widgets.essayPlainText,
        textInputBgColor: themeColorsMap.YAB.themeColor,
        textInputColor: themeColorsMap.YAB.themeSecondaryColor,
        textInputBorderColor: themeColorsMap.YAB.themeSecondaryColor,
        toolbarItemBgHoverColor: themeColorsMap.YAB.themeColor,
        toolbarItemBgActiveColor: themeColorsMap.YAB.themeColor,
      },
      matchList: {
        ...defaultTheme.widgets.matchList,
        dragItemColor: themeColorsMap.YAB.themeSecondaryColor,
        containerBgColor: themeColorsMap.YAB.themeColor,
        separatorBgColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      chart: {
        ...defaultTheme.widgets.chart,
        bgColor: themeColorsMap.YAB.themeColor,
        labelStrokeColor: themeColorsMap.YAB.themeSecondaryColor,
        labelBgHoverColor: themeColorsMap.YAB.themeColor,
        axisBorderColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      hotspot: {
        ...defaultTheme.widgets.hotspot,
        svgMapStrokeColor: themeColorsMap.YAB.themeSecondaryColor,
      },
      axisLabels: {
        ...defaultTheme.widgets.axisLabels,
        responseBoxBgColor: themeColorsMap.YAB.themeColor,
      },
      graphPlacement: {
        ...defaultTheme.widgets.graphPlacement,
        buttonLabelStroke: themeColorsMap.YAB.themeSecondaryColor,
        buttonActiveLabelStroke: themeColorsMap.YAB.themeColor,
        buttonActiveBgColor: themeColorsMap.YAB.themeSecondaryColor,
        buttonHoverBgColor: themeColorsMap.YAB.themeColor,
      },
    },
  },
  default: defaultTheme,
  scanScore: {
    ...defaultTheme,
    styledDropZone: {
      containerWidth: 'auto',
      containerMargin: '40px',
      containerPadding: '50px',
      containerBorderRadius: '4px',
      containerBorderColor: '#b9b9b9',
      containerFocusBorderColor: lightBlue7,
      containerColor: containerWhite,
      containerDragActiveColor: actionIconColor,
      containerBackground: containerGrey,
      iconUploadWidth: '70px',
      iconUploadHeight: '60px',
      iconUploadColor: uploadIcon,
      iconUploadDragActiveColor: actionIconColor,
      loadingIconFontSize: questionTexticonFontSize,
      zoneTitleFontSize: '15px',
      zoneTitleCommentFontSize: '12px',
      zoneTitleFontWeight: bold,
      zoneTitleColor: zoneDropFontColor,
      zoneTitleAltColor: zoneDropFontColorAlt,
      underlinedColor: themeColorBlue,
    },
  },
  zoomed: (theme) => ({
    headerTitleFontSize: `${theme.size2}px`,
    headerClassTitleFontSize: `${theme.size4}px`,
    headerDropdownFontSize: `${theme.size4}px`,
    confirmationPopupButtonTextSize: `${theme.size4}px`,
    noData: {
      ...theme.noData,
      NoDataArchiveTextSize: `${theme.size3}px`,
      NoDataArchiveSubTextSize: `${theme.size4}px`,
    },
    sideMenu: {
      ...theme.sideMenu,
      zoom: ThemeZoomMap[theme.zoomLevel],
    },
    profile: {
      ...theme.profile,
      formInputLabelSize: `${theme.size6}px`,
      profileDetailFontSize: `${theme.size4}px`,
      saveButtonTextSize: `${theme.size6}px`,
      userHeadingTextSize: `${theme.size3}px`,
      changePasswordTextSize: `${theme.size6}px`,
    },
    confirmation: {
      ...theme.confirmation,
      descriptionTextSize: `${theme.size3}px`,
      modalWidth: ThemeZoomMap[theme.zoomLevel],
    },
    header: {
      ...theme.header,
      headerTitleSecondaryTextSize: `${theme.size4}px`,
    },
    headerFilters: {
      ...theme.headerFilters,
      headerFilterTextSize: `${theme.size5}px`,
    },
    breadcrumbs: {
      ...theme.breadcrumbs,
      breadcrumbTextSize: `${theme.size5}px`,
    },
    manageClass: {
      ...theme.manageClass,
      NoDataArchiveTextSize: `${theme.size2}px`,
      NoDataArchiveSubTextSize: `${theme.size3}px`,
    },
  }),
  playerSkin: {
    magnifierBorderColor: '#7A7A7A',
    parcc: {
      header: {
        background: 'white',
        logoColor: themeColor,
      },
      defaultButton: {
        background: '#EEEEEE',
        color: '#7A7A7A',
        active: {
          background: '#7A7A7A',
          color: '#ffffff',
        },
      },
      navigationButtons: {
        background: '#0564CC',
        color: '#ffffff',
        fontSize: '11px',
      },
      menuItem: {
        hover: {
          background: '#0564CC',
          color: 'white',
        },
        activeColor: '#ff9900',
      },
      breadcrumb: {
        background: '#666666',
        color: 'white',
        fontSize: '11px',
      },
      tools: {
        color: '#EEEEEE',
        svgColor: '#7A7A7A',
        active: {
          color: '#4A4A4A',
          svgColor: 'white',
          background: '#4A4A4A',
        },
      },
      review: {
        card: {
          background: 'lightgrey',
        },
      },
    },
    sbac: {
      header: {
        borderColor: '#DADAE4',
        background: 'white',
        logoColor: themeColor,
        topMenu: {
          background: '#F2FBFF',
        },
      },
      navigationButtons: {
        background: 'white',
        color: '#00AD50',
        hover: {
          background: '#00AD50',
          color: 'white',
        },
      },
      defaultButton: {
        color: greyThemeDark1,
        disabledColor: '#d9d9d9',
        hover: {
          background: '#00AD50',
          color: 'white',
        },
        audioPauseColor: '#00AD50',
        questionMarkColor: '#00AD50',
      },
    },
    quester: {
      header1: {
        background: '#2f4151',
        border: '#2f4151',
      },
      header2: {
        background: '#374a5b',
      },
      button: {
        background: '#ffffff',
        textColor: '#374a5b',
      },
      footer: {
        background: '#2f4151',
        border: '#2f4151',
        textColor: '#fff',
        textHoverColor: '#ffffff',
        hover: {
          color: '#ffffff',
          background: '#445666',
        },
      },
      review: {
        filterHoverBg: '#1A73E8',
        textHoverColor: '#ffffff',
        textColor: '#1AB394',
      },
    },
    drc: {
      header1: {
        background: '#2f4151',
        border: '#2f4151',
      },
      header2: {
        background: '#3f85e5',
        textColor: '#ffffff',
      },
      button: {
        background: '#ffffff',
        textColor: '#3f85e5',
      },
      footer: {
        background: '#3F85E5',
        border: '#3F85E5',
        textColor: '#fff',
        textHoverColor: '#ffffff',
        hover: {
          color: '#ffffff',
          background: '#445666',
        },
      },
      review: {
        filterHoverBg: '#1A73E8',
        textHoverColor: '#ffffff',
        textColor: '#1AB394',
      },
    },
  },
}

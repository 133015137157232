/* eslint-disable react/prop-types */
import React from 'react'
import withIconStyles from './HOC/withIconStyles'
import SVG from './common/SVG'

const IconSettings = (props) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.582 16.581"
    {...props}
  >
    <g transform="translate(0.001 -0.013)">
      <path
        d="M15.975,7.1l-1.643-.274a6.187,6.187,0,0,0-.52-1.39l1.052-1.3a.725.725,0,0,0-.022-.938l-.653-.735a.725.725,0,0,0-.929-.133l-1.4.883a6.18,6.18,0,0,0-2.073-.935L9.507.619A.725.725,0,0,0,8.792.013H7.807a.723.723,0,0,0-.714.606L6.816,2.271A6.177,6.177,0,0,0,5.073,3L3.737,2.042a.726.726,0,0,0-.935.077l-.7.7a.725.725,0,0,0-.077.935l.956,1.338a6.193,6.193,0,0,0-.719,1.731L.606,7.1A.725.725,0,0,0,0,7.812V8.8a.725.725,0,0,0,.606.716l1.661.277a6.175,6.175,0,0,0,.55,1.446l-1.047,1.3a.725.725,0,0,0,.022.938l.653.735a.726.726,0,0,0,.929.133l1.425-.9a6.172,6.172,0,0,0,2.017.9l.276,1.652a.723.723,0,0,0,.714.606h.984a.725.725,0,0,0,.715-.606l.275-1.652a6.169,6.169,0,0,0,1.724-.712l1.392.994a.724.724,0,0,0,.934-.077l.7-.7a.723.723,0,0,0,.076-.934l-.991-1.389a6.163,6.163,0,0,0,.72-1.736l1.643-.274a.724.724,0,0,0,.606-.716V7.812A.725.725,0,0,0,15.975,7.1ZM8.317,11.413A3.109,3.109,0,1,1,11.426,8.3,3.11,3.11,0,0,1,8.317,11.413Z"
        transform="translate(0)"
      />
    </g>
  </SVG>
)

export default withIconStyles(IconSettings)
